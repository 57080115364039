import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useLocation, useRoutes} from 'react-router-dom';
import { MainLayout, DashboardLayout, StudioLayout } from 'src/layouts';
import LoadingScreen from 'src/components/commons/LoadingScreen';
import GuestGuard from 'src/guards/GuestGuard';
import { PATH_PAGE, PATH_ACCOUNT, PATH_STUDIO, PATH_SHOP, PATH_AUTH, PATH_DASHBOARD} from './paths';
import RoleGuard from 'src/guards/RoleGuard';
import ShopCheckoutView from 'src/views/shop/ShopCheckoutView';

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isStudio = pathname.includes('/studio');

  return (
    <Suspense fallback={<LoadingScreen isStudio={isStudio} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: PATH_AUTH.root,
      children: [
        {
          path: PATH_AUTH.login,
          element: (
            <GuestGuard>
              <LoginView />
            </GuestGuard>
          ),
        },
        {
          path: PATH_AUTH.forgottenPassword,
          element: (
            <GuestGuard>
              <ForgottenPasswordView />
            </GuestGuard>
          ),
        },
        {
          path: PATH_AUTH.resetPassword(':uuid'),
          element: (
            <GuestGuard>
              <ResetPasswordView />
            </GuestGuard>
          ),
        },
        {
          path: PATH_AUTH.register,
          element: (
            <GuestGuard>
              <RegisterView />
            </GuestGuard>
          ),
        },
      ],
    },
  
    // DASHBOARD
    {
      path: '/erode_dashboard',
      element:  <RoleGuard hasContent roles={['admin']}><DashboardLayout /></RoleGuard>,
      children: [
        { path: PATH_DASHBOARD.root, element: <DashboardView /> },
        { path: PATH_DASHBOARD.pampilles.list, element: <DashboardProductListView /> },
        { path: PATH_DASHBOARD.pampilles.edit(':id'), element: <DashboardProductEditview /> },
        { path: PATH_DASHBOARD.pampilles.create, element: <DashboardProductEditview /> },
        { path: PATH_DASHBOARD.orders.list, element: <DashboardOrderListView /> },
        { path: PATH_DASHBOARD.orders.view(':id'), element: <DashboardOrderDetailView /> },
        { path: PATH_DASHBOARD.users.list, element: <DashboardUserListView /> },
      ]
    },

    // PAGES
    {
      path: '/',
      element: <MainLayout />,
      children: [

        // WEBSITE
        { path: PATH_PAGE.home, element: <HomeView /> },
        { path: PATH_PAGE.cgv, element: <CGVView /> },
        { path: PATH_PAGE.legal_notice, element: <LegalNoticeView /> },
        { path: PATH_PAGE.company, element: <CompanyView /> },
        { path: PATH_PAGE.contact, element: <ContactView /> },
        { path: PATH_PAGE.email_confirmation(':uuid'), element: <EmailConfirmationView /> },

        // SHOP 
        { path: PATH_SHOP.root, element: <ShopView /> },
        { path: PATH_SHOP.checkout, element: <ShopCheckoutView /> },
        { path: 'shop/products/:id', element: <ShopProductDetailsView /> },
        
        // USER ACCOUNT
        { path: PATH_ACCOUNT.root, element: <UserAccountMainView /> },
        { path: PATH_ACCOUNT.models, element: <UserAccountModelView /> },
        { path: PATH_ACCOUNT.images, element: <UserAccountImageView /> },
        { path: PATH_ACCOUNT.settings, element: <UserAccountSettingsView /> },
        { path: PATH_ACCOUNT.shipping, element: <UserAccountShippingView /> },
        { path: PATH_ACCOUNT.order.list, element: <UserAccountOrderView /> },
        { path: PATH_ACCOUNT.order.view(':id'), element: <UserOrderDetailView /> },
        { path: 'myaccount/order/:id', element: <UserOrderDetailView /> },
      ],
    },

    // STUDIO
    {
      path: PATH_STUDIO.root,
      element: <StudioLayout />,
      children: [
        { path: PATH_STUDIO.root, element: <StudioView /> },
      ],
    },

    // HEALTH CHECK
    {
      path: '/health',
      element: <h3>Healthy app</h3>
    },


    // OTHERS
    {
      path: '*',
      element: <MainLayout />,
      children: [
        { path: '404', element: <View404 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// PAGES
const HomeView = Loadable(lazy(() => import('../views/HomeView')));
const CGVView = Loadable(lazy(() => import('../views/CGVView')));
const LegalNoticeView = Loadable(lazy(() => import('../views/LegalNoticeView')));
const CompanyView = Loadable(lazy(() => import('../views/CompanyView')));
const ShopView = Loadable(lazy(() => import('../views/shop/ShopView')));
const ContactView = Loadable(lazy(() => import('../views/ContactView')));
const StudioView = Loadable(lazy(() => import('../views/StudioView')));
const ShopProductDetailsView = Loadable(lazy(() => import('../views/shop/ShopProductDetailsView')));
const View404 = Loadable(lazy(() => import('../views/View404')));

// DASHBOARD
const DashboardView = Loadable(lazy(() => import('../views/dashboard/DashboardView')));
const DashboardProductListView = Loadable(lazy(() => import('../views/dashboard/products/DashboardProductListView')));
const DashboardProductEditview = Loadable(lazy(() => import('../views/dashboard/products/DashboardProductEditView')));

const DashboardOrderListView = Loadable(lazy(() => import('../views/dashboard/orders/DashboardOrderListView')));
const DashboardOrderDetailView = Loadable(lazy(() => import('../views/dashboard/orders/DashboardOrderDetailView')));
const DashboardUserListView = Loadable(lazy(() => import('../views/dashboard/users/DashboardUserListView')));


// AUTH AND USER
const LoginView = Loadable(lazy(() => import('../views/auth/LoginView')));
const ForgottenPasswordView = Loadable(lazy(() => import('../views/auth/ForgottenPasswordView')));
const ResetPasswordView = Loadable(lazy(() => import('../views/auth/ResetPasswordView')));
const RegisterView = Loadable(lazy(() => import('../views/auth/RegisterView')));
const UserAccountMainView = Loadable(lazy(() => import('../views/user/UserAccountMainView')));
const UserOrderDetailView = Loadable(lazy(() => import('../views/user/order/UserOrderDetailView')));
const UserAccountModelView = Loadable(lazy(() => import('src/views/user/UserAccountModelView')));
const UserAccountOrderView = Loadable(lazy(() => import('src/views/user/UserAccountOrderView')));
const UserAccountSettingsView = Loadable(lazy(() => import('src/views/user/UserAccountSettingsView')));
const UserAccountImageView = Loadable(lazy(() => import('src/views/user/UserAccountImageView')));
const UserAccountShippingView = Loadable(lazy(() => import('src/views/user/UserAccountShippingView')));

// OTHERS
const EmailConfirmationView = Loadable(lazy(() => import('../views/EmailConfirmationView')));
