// mui
import { useEffect, useState } from 'react';
import { Box, Grid, Button, Card, Typography } from '@mui/material';
import { BillingAddress as Address, OnCreateBilling } from 'src/@types/checkout';

// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { onBackStep, onNextStep, createBilling } from 'src/redux/slices/checkout/actions';

// components
import Iconify from 'src/components/commons/Iconify';
import CheckoutSummary from './CheckoutSummary';
import CheckoutNewAddressForm from './CheckoutNewAddressForm';
import useLocales from 'src/hooks/useLocales';

// services
import AddressService from 'src/services/addressService';

/* Billing Address List Component
*  Displays the list of registered billing addresses in the second step of checkout.
**/
export default function CheckoutBillingAddress() {
  const dispatch = useDispatch();

  const {translate} = useLocales();

  const { total, subtotal, shipping } = useSelector((state) => state.checkout);
  const [open, setOpen] = useState(false);
  const [addresses, setAddresses] = useState<FullAddress[]>([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNextStep = () => {
    dispatch(onNextStep());
  };

  const handleBackStep = () => {
    dispatch(onBackStep());
  };

  const handleCreateBilling = (value: Address) => {
    dispatch(createBilling(value));
  };

  const handleOnDelete = (id:number) => {
    AddressService.delete(id.toString());
    const deleteAddress = addresses.filter((address) => address.id !== id);
    setAddresses(deleteAddress);
  }

  useEffect(() => {
    const fetchData = async () =>{
      try {
        const {data: response} = await AddressService.getAll();
        setAddresses(response);
      } catch (error) {
        console.error(error.message);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              size="small"
              color="inherit"
              onClick={handleBackStep}
              startIcon={<Iconify icon={'eva:arrow-ios-back-fill'} />}
            >
              {translate('view.shop_checkout.section.steps.back')}
            </Button>
            <Button
              size="small"
              onClick={handleClickOpen}
              startIcon={<Iconify icon={'eva:plus-fill'} />}
            >
              {translate('view.shop_checkout.section.billing_address.form.add_button_text')}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          {addresses.map((address:any, index:number) => {
            return <AddressItem
              key={index}
              addressItem={address}
              onNextStep={handleNextStep}
              onCreateBilling={handleCreateBilling}
              onDeleteAddress={handleOnDelete}
            />
          })}
        </Grid>

        <Grid item xs={12} md={4}>
          <CheckoutSummary subtotal={subtotal} shipping={shipping} total={total} />
        </Grid>
      </Grid>

      <CheckoutNewAddressForm
        open={open}
        onClose={handleClose}
        onNextStep={handleNextStep}
        onCreateBilling={handleCreateBilling}
      />
    </>
  );
}

interface FullAddress extends Address {
  id: number;
}

type AddressItemProps = {
  addressItem: FullAddress;
  onNextStep: VoidFunction;
  onCreateBilling: OnCreateBilling;
  onDeleteAddress: any;
};

// 

/**
 * Display client's addresses. Order is triggered when address is chosen
 * @param addressItem 
 * @param onNextStep 
 * @param onCreateBilling 
 * @param onDeleteAddress 
 * @returns 
 */
function AddressItem({ addressItem, onNextStep, onCreateBilling, onDeleteAddress}: AddressItemProps) {

  const handleCreateBilling = async () => {
    onCreateBilling(addressItem);
    onNextStep();
  };

  const {translate} = useLocales();

  return (
    <Card sx={{ p: 3, mb: 3, position: 'relative' }}>
      <Box sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
        <Typography variant="subtitle1">{addressItem.addressName}</Typography>
      </Box>
      <Typography variant="body2">
        {addressItem.fullName}
      </Typography>
      <Typography variant="body2">
        {addressItem.address}
      </Typography>
      <Typography variant="body2">
        {addressItem.postalCode}  {addressItem.city}
      </Typography>
      <Typography variant="body2" gutterBottom>
        {addressItem.country}
      </Typography>
      <Box
        sx={{
          mt: 3,
          display: 'flex',
          position: { sm: 'absolute' },
          right: { sm: 24 },
          bottom: { sm: 24 },
        }}
        >
        <Button onClick={() => onDeleteAddress(addressItem.id)} variant="outlined" size="small" color="inherit">
          {translate('view.shop_checkout.section.billing_address.list.delete_button_text')}
        </Button>
        <Box sx={{ mx: 0.5 }} />
        <Button variant="outlined" size="small" onClick={handleCreateBilling}>
          {translate('view.shop_checkout.section.billing_address.list.deliver_button_text')}
        </Button>
      </Box>
    </Card>
  );
}