import { useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Grid, Step, Stepper, Container, StepLabel, StepConnector } from '@mui/material';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { PATH_SHOP } from '../../routes/paths';
import useIsMountedRef from '../../hooks/useIsMountedRef';
// components
import View from 'src/components/commons/View';
import Iconify from 'src/components/commons/Iconify';
import HeaderBreadcrumbs from 'src/components/commons/HeaderBreadcrumbs';
import {CheckoutCartCard} from 'src/sections/shop/checkout';
import { getCart, createBilling } from 'src/redux/slices/checkout/actions';
import useLocales from 'src/hooks/useLocales';
import CheckoutBillingAddress from 'src/sections/shop/checkout/CheckoutBillingAddress';
import CheckoutOrderComplete from 'src/sections/shop/checkout/CheckoutOrderComplete';
import CheckoutPayment from 'src/sections/shop/checkout/CheckoutPayment';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(5),
}));
  
const STEPS = ['view.shop_checkout.section.steps.cart', 'view.shop_checkout.section.steps.billing', 'view.shop_checkout.section.steps.payment'];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  top: 10,
  left: 'calc(-50% + 20px)',
  right: 'calc(50% + 20px)',
  '& .MuiStepConnector-line': {
    borderTopWidth: 2,
    borderColor: theme.palette.divider,
  },
  '&.Mui-active, &.Mui-completed': {
    '& .MuiStepConnector-line': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

function QontoStepIcon({ active, completed }: { active: boolean; completed: boolean }) {
  return (
    <Box
      sx={{
        zIndex: 9,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: active ? 'primary.main' : 'text.disabled',
      }}
    >
      {completed ? (
        <Iconify
          icon={'eva:checkmark-fill'}
          sx={{ zIndex: 1, width: 20, height: 20, color: 'primary.main' }}
        />
      ) : (
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
          }}
        />
      )}
    </Box>
  );
}

export default function ShopCheckoutView() {
  const dispatch = useDispatch();
  const {translate} = useLocales();
  const isMountedRef = useIsMountedRef();
  const { cart, billing, activeStep  } = useSelector((state) => state.checkout);
  const isComplete = activeStep === STEPS.length;

  useEffect(() => {
    if (isMountedRef.current) {
      dispatch(getCart(cart));
    }
  }, [dispatch, isMountedRef, cart]);

  useEffect(() => {
    if (activeStep === 1) {
      dispatch(createBilling(null));
    }
  }, [dispatch, activeStep]);

  return (
    <View title={translate('view.shop_checkout.view_title')}>
        <RootStyle>
            <Container maxWidth={'lg'}>
                <HeaderBreadcrumbs
                heading={translate('view.shop_checkout.view_title')}
                links={[
                    {
                    name: translate('view.shop_checkout.section.breadcrumbs.title'),
                    href: PATH_SHOP.root,
                    },
                    { name: translate('view.shop_checkout.section.breadcrumbs.checkout') },
                ]}
                />

                <Grid container justifyContent={isComplete ? 'center' : 'flex-start'}>
                <Grid item xs={12} md={12} sx={{ mb: 5 }}>
                    <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                    {STEPS.map((label) => (
                        <Step key={label}>
                          <StepLabel
                              StepIconComponent={QontoStepIcon}
                              sx={{
                              '& .MuiStepLabel-label': {
                                  typography: 'subtitle2',
                                  color: 'text.disabled',
                              },
                              }}
                          >
                            {translate(label)}
                        </StepLabel>
                        </Step>
                    ))}
                    </Stepper>
                </Grid>
                </Grid>

                {!isComplete ? (
                  <>
                    {activeStep === 0 && <CheckoutCartCard />}
                    {activeStep === 1 && <CheckoutBillingAddress />}
                    {activeStep === 2 && billing && <CheckoutPayment />}
                  </>
                ) : (
                  <CheckoutOrderComplete open={isComplete} />
                )}
            </Container>
      </RootStyle>
    </View>
  );
}
