// @mui
import { Card, Button, Typography, CardHeader, CardContent } from '@mui/material';
// redux
import { useSelector } from 'src/redux/store';
// components
import Iconify from 'src/components/commons/Iconify';
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

type Props = {
  onBackStep: VoidFunction;
};

/* Checkout billing information card.
*  Displays the chosen billing address in a card.
**/
export default function CheckoutBillingInfo({ onBackStep }: Props) {
  const { billing } = useSelector((state) => state.checkout);
  const { translate } = useLocales();

  return (
    <Card sx={{ mb: 3 }}>
      <CardHeader
        title={translate('view.shop_checkout.section.billing_address_card.title')}
        action={
          <Button size="small" startIcon={<Iconify icon={'eva:edit-fill'} />} onClick={onBackStep}>
            {translate('view.shop_checkout.section.billing_address_card.edit_button_text')}
          </Button>
        }
      />
      <CardContent>
        <Typography variant="subtitle2" gutterBottom>
          {billing?.fullName}&nbsp;
        </Typography>

        <Typography variant="body2">
          {billing?.address}
        </Typography>
        <Typography variant="body2">
          {billing?.postalCode} {billing?.city}
        </Typography>
        <Typography variant="body2">
          {billing?.country}
        </Typography>
      </CardContent>

    </Card>
  );
}
