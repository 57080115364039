const en = {
    // COMMON VOCABULARY
    commons:{
        yes: 'Yes',
        no: 'No',
        actions: {
            open: 'Open',
            close: 'Close',
            save: 'Save',
            create: 'Create',
            edit: 'Edit',
            delete: 'Delete',
        },

        orders:{
            my_orders: 'My orders',
            no_orders: 'No orders',
            status: {
                paid: 'Paid',
                created: 'Created',
                passed: 'Passed'
            }
        },

        pampille:{
            finish: 'Finish',
            treatment: 'Treatment'
        }
    },
    // PAGES
    view: {
        home: {
            view_title: 'Home',
            section: {
                welcome: {
                    hi: 'Hi',
                    create_message: 'Create your pendant',
                    hero_message: 'Use our online editor to choose a model to customize',
                    start_button: 'Start',
                    button_to_admin_text: 'Dashboard',
                    my_creations: 'My pendants',
                    my_orders: 'My orders'
                },
                catalog: {
                    header_subtitle: 'Our products',
                    title: 'For each design, we have a pendant',
                    description: 'We offer a wide choice of pendants of different sizes and shapes to meet your most specific needs and allow you to freely create the pendant that corresponds to your visual identity.',
                    toShopButton: 'See our catalog'
                },
                contact: {
                    title: 'A question ? A specific request ?',
                    description: 'Contact us to adress your pendant project',
                    button_text: 'Contact us'
                }
            },
        },
        company: {
            view_title: 'Our expertise',
            section: {
                info: {
                    header_subtitle: 'Who are we ?',
                    title: 'Industrial solutions in metallurgy since 1987',
                    description: 'ERODE designs and manufactures ornamental metal accessories for fashion, decoration, cosmetics, aeronautics and construction. Our creations are made by laser cutting, stamping, stamping of all types of materials: tinplate, brass, steel, wood, plastic, paper, rubber... as well as by surface treatment (polishing and electrolytic deposition.',
                    button: 'More about us'
                },
                made_in_france: {
                    header_subtitle: 'French expertise',
                    title: 'Made in France',
                    description: 'From the design of the tassel to its personalization, all the manufacturing stages are carried out in France.'
                },
                shipping: {
                    title: 'Fast design and shipping',
                    description: 'From the placing of your order, to the dispatch of your order, including the design and verification of your tassels, Érode guarantees delivery within 2 weeks.'
                },
            }
        },
        studio: {
            view_title: 'Studio',
            section: {
                sidebar: {
                    text: {
                        title: 'Text',
                        description: 'Change your text input bellow or modify it right on the studio.',
                        input_label: 'Text to add',
                        button_label: 'New text zone',
                        empty_title: 'You have no text in the studio',

                    },
                    image: {
                        title: 'Image',
                        description: 'Import an image or a logo to customize your pendant',
                        format: 'Accepted file types',
                        format_list: 'Only the following file types are accepted : .jpg, .jpeg, .png, .gif.',
                        button_label: 'Import',
                        empty_library_title: 'You have not saved any image yet',
                        empty_library_description: 'You can import them here and find them when you log in',
                        modal: {
                            add_button_text: 'Add to studio'
                        }
                    },
                    settings: {
                        title: 'Settings',
                        description: 'Use different settings to ease your creation process',
                        dimensions_toggle: 'Toggle dimensions',
                        grid_toggle: 'Positioning grid'
                    },
                    summary: {
                        add_to_cart: 'Add to cart',
                        save_model: 'Save model',
                        success_message: 'Pendant added to your cart',
                        success_model_save: 'Your pendants has been sucessfully saved.',
                        error_model_save: 'You must sign in to save a pendant.'
                    },
                },
                canvas:{
                    textActionBar: {
                        font: 'Font'
                    },
                    other_products: 'See catalog'
                },
                marking_modal:{
                    title: 'Choose your type of marking',
                    description: 'Your creations can be engraved by laser marking or struck by punch. The price of the creation of the punch is 150€ for the first order.',
                    marking_type: 'Marking type',
                    graved: 'Laser marking',
                    hit: 'Punch',
                    poincon: 'Have you already ordered the punch associated with this model ?',
                },
                model_modal:{
                    title: 'Save this model',
                    description: 'You can find this creation on your account to modify it later.',
                    model_name: 'Creation name'
                }
            }
        },
        shop: {
            view_title: 'Our pendants',
            title: 'Catalog',
            subtitle: 'Our pendants',
            filter: {
                search_bar_label: 'Search for a pendant'
            },
            section: {
                card: {
                    on_quote: 'On quote'
                }
            }
        },
        shop_details: {
            view_title: 'Product details',
            section: {
                breadcrumbs: {
                    title: 'Product details',
                    subtitle: 'Our pendants'
                },  
                search_bar: {
                    search: 'Search a pendant',
                    not_found: 'Unknown pendent',
                    please_enter_keyword: 'Please type a keyword',
                    no_results: 'Not result for : ',
                    please_check: 'Please type another word or check for typos'
                },
                product_card: {
                    description: 'Description',
                    dimensions: 'Dimensions',
                    price: 'Price',
                    finish: 'Finish',
                    customize: 'Customize'
                }
            }
        },
        shop_checkout: {
            view_title: 'Checkout',
            continue_shopping: 'Go back to shop',
            model_creation_loading: 'Saving your creations, you will be redirected to the payment page.',
            section: {
                breadcrumbs:{
                    title: 'Our pendants',
                    checkout: 'Cart'
                },
                billing_address: {
                    form: {
                        address_name_label: 'Address name',
                        full_name_label: 'Full name',
                        phone_number_label: 'Phone number',
                        address_label: 'Address',
                        town_label: 'Town / City',
                        postal_code_label: 'Zip/Postal code',
                        country_label: 'Country',
                        add_button_text: 'Add a new address',
                        deliver_to_this_address: 'Deliver to this address',
                        add_address: 'Add address',
                        edit_address: 'Edit address',
                        cancel_button_text: 'Cancel',
                        use_as_default_address: 'Use a default address' 
                    },
                    list: {
                        delete_button_text: 'Delete',
                        deliver_button_text: 'Deliver to this address'
                    }
                },
                billing_address_card: {
                    title: 'Delivery address',
                    edit_button_text: 'Edit'
                },
                delivery_options_card:{
                    title: 'Delivery options',
                    standard_delivery_title: 'Standard Delivery',
                    standard_delivery_description: 'At your home within 5 days',
                    fast_delivery_title: 'Fast delivery',
                    fast_delivery_description: 'At your home within 2 days'
                },
                payment_options_card:{
                    title: 'Payment options'
                },
                summary_card:{
                    title: 'Order Summary',
                    subtotal: 'Sub-total',
                    shipping: 'Shipping',
                    total: 'Total',
                    total_ht: 'Total Without Tax',
                    total_ttc: 'Total All Tax included',
                    vat_included: 'VAT included if applicable',
                    free: 'Free'
                },
                steps: {
                    cart: 'Cart',
                    billing: 'Billing & Shipping',
                    payment: 'Payment',
                    back: 'Back'
                },
                cart_list: {
                    title: 'Your cart',
                    items: 'Product(s)',
                    empty_title: 'Your cart is empty',
                    empty_description: 'You have not added any item to your cart yet.',
                    table_head: {
                        product: 'Product',
                        price: 'Price',
                        quantity: 'Quantity',
                        total_price: 'Total price'
                    }
                },
                order_complete: {
                    title: 'Thank you for your purchase',
                    thanks: 'Our services successfully received your order N° ',
                    notice: 'We will send you an e-mail to let you know when your parcel has been sent.',
                    question: 'If you have any questions or requests, please contact us.',
                    greetings: 'Best regards',
                    download_button_text: 'Download as PDF',
                    back_to_shop: 'Back to shop',
                    back_to_orders: 'Back to my orders'
                }
            }
        },
        admin_dashboard: {
            view_title: 'Admin dashboard'
        },
        auth: {
            login: {
                view_title: 'Sign in',
                login: 'Login',
                logout: 'Logout',
                details: 'Sign in to follow your orders and save your creations',
                no_account: 'Do not have an account yet ?',
                get_started: 'Sign up',
                form: {
                    title: 'Sign in',
                    username_label: 'Username',
                    email_label: 'E-mail',
                    password_label: 'Password',
                    button_text: 'Login',
                    error_text: 'Wrong credentials',
                    validation_text: {
                        email: 'Email must be valid',
                        password: 'Password is mandatory'
                    },
                    required_text: {
                        username: 'A username is required',
                        email: 'An email address is required',
                        password: 'A password is required',
                    },
                    register: 'Sign in',
                    account_exist: 'Already have an account ?',
                    remember_me: 'Remember me',
                    forgotten_password: 'Forgotten password ?',
                    error_message: {
                        'Wrong password': 'Wrong password',
                        "User doesn't exists": "User doesn't exists",
                        'Request body validation failed': 'An error occured, please try again',
                    }
                }
            },
            register: {
                view_title: 'Create an account',
                create_account: 'Register',
                account_exist: 'Already have an account ?',
                register_button_label: 'Register',
                form: {
                    error_message: {
                        'Request body validation failed': 'An error occured, please try again',
                        'User already exists': 'This address is already used'
                    }
                }
            },
            forgotten_password: {
                view_title: 'Forgotten password',
                form_button_text: 'Restore my password',
                details: 'Fill in the email address of your account to restore your password.',
            },
            reset_password: {
                view_title: 'Password restoration',
                form_button_text: 'Restore password',
                details: 'Please type a new password to use for your account.',
                success: 'Votre mot de passe a bien été modifié.'
            }
        },
        user_account: {
            view_title: 'My account',
            orders: {
                view_title: 'My orders',
                order_row:{
                    date: 'Ordered on',
                    id: 'ID',
                    total_price: 'Total price',
                    status: 'Status',
                    details: 'Details'
                }
            },
            images: {
                view_title: 'My images',
                no_images: 'No saved images',
                delete_success: 'Your image has been deleted.'
            },
            settings: {
                view_title: 'My settings',
                section: {
                    change_mail:{
                        title: 'Change email',
                        description: 'Here you can change your email address to log in to your account.',
                        form: {
                            error: 'We cannot change this email',
                            success: 'Your email address has been changed. An email has been sent to you to confirm it.',
                            labels:{
                                new_email: 'New email address',
                            },
                            validation_text:{
                                new_email: 'Must be a valid email'
                            },
                            required_text:{
                                new_email: 'New email required'
                            }
                        }
                    },
                    change_password:{
                        title: 'Password change',
                        description: 'You can change your password at any time. A password must contain at least 6 characters.',
                        form: {
                            error: 'We cannot change this password',
                            success: 'Your password has been successfully changed',
                            labels:{
                                old_password: 'Old password',
                                new_password: 'New password'
                            },
                        }
                    },
                    delete_account: {
                        title: 'Account deletion',
                        description: 'You can delete your account and all data associated with it.',
                    }
                }
            },
            models: {
                view_title: 'My pendants',
                no_creations: 'No creations saved'
            },
            billing:{
                view_title: 'Payment and shipping'
            },
            shipping:{
                view_title: 'My addresses',
                delete_success: 'Address successfully deleted.',
            },
            model_card: {
                title: 'My pendants',
                description: 'Find your saved pendants here to ease future orders.'
            },
            order_card: {
                title: 'My orders',
                description: 'Follow your current and past orders.'
            },
            billing_card:{
                title: 'Payment and shipping',
                description: 'Change your prefered payment methods and shipping/billing addresses.'
            },
            shipping_card: {
                title: 'Addresses',
                description: 'Add, delete or edit your billing and delivery addresses.'
            },
            image_card: {
                title: 'My images',
                description: 'Find the images you imported while creating your pendants.'
            },
            setting_card: {
                title: 'Account settings',
                description: 'Change your security settings, such as password and email address.'
            },
            section: {
                breadcrumbs: {
                    my_account: 'My account',
                    my_models: 'My pendants',
                    my_images: 'My images',
                    my_billings: 'Payment and shipping',
                    my_addresses: 'My addresses',
                    settings: 'Account settings'
                },
                user_card: {
                    client_number: 'Client number',
                    email: 'Email',
                },
                tabs: {
                    overview: 'Mon profil',
                    models: 'My creations',
                    orders: 'Mes commandes',
                    settings: 'Paramètres du compte'
                },
                panels:{
                    overview: {
                        my_addresses: 'My addresses'
                    },
                    models: {
                        model_id: 'ID',
                        product_name: 'Pendant\'s name',
                        product_id: 'ID number',
                        finish: 'Finish',
                        delete: 'Delete',
                        edit: 'Edit',
                        add_cart: 'Add to cart',
                        delete_success: 'Your pendant has been deleted.',
                        delete_info: 'Your creation can'
                    }
                },
            }
        },
        contact: {
            view_title: 'Contact',
            section: {
                form: {
                    title: 'In need for a quote or more information ? Please contact us.',
                    name_label: 'Your name',
                    email_label: 'Email',
                    subject_label: 'Subject of your request',
                    message_label: 'Your message',
                    send_button_label: 'Send',
                    required_text: {
                        name: 'A name is required',
                        email: 'An email is required',
                        subject: 'Please indicate the subject of your demand',
                        message: 'A message is required'
                    },
                    validation_text: {
                        email: 'L\'adresse n\'est pas valide'
                    },
                    success_message: 'Nous vous remercions pour votre message. Nos équipes feront le maximum pour vous répondre dans les plus brefs délais.'
                }
            }
        },
        error: {
            not_found: {
                view_title: 'Error 404',
                title: 'Sorry this page does not exist !',
                message: 'It seems that we could not find the ressource you are looking for.',
                redirect_button_text: 'Back to home page'
            }
        },
        email_confirmation:{
            view_title: 'Email confirmation',
            true:{
                title: 'Congrats, your email has been confirmed !',
                message: 'You can now save your creations and personalize your account.',
            },
            false:{
                title: 'Impossible to confirm your email address.',
                message: 'Please check if your address is valid.',
            },
            redirect_button_text: 'Login'
        }
    },



    // LAYOUT

    layout: {
        navigation: {
            home: 'Home',
            shop: 'Our pendants',
            company: 'Our company',
            admin: 'Admin dashboard',
            contact: 'Contact',
            my_account: 'My account',
            login_button: 'Login'
        },
        footer: {
            copyright: 'All rights reserved',
            legal_notice: 'Legal Notice',
            privacy_policy: 'Privacy policy',
            contact_us: 'Contact us',
            our_products: 'Our pendants'
        },
        cart: {
            title: 'Your cart',
            total: 'Total',
            quantity: 'Quantity',
            finish: 'Finish',
            unit_price: 'Unit price',
            price: 'Price',
            subtotal: 'Sub-total',
            total_ht: 'Total without Tax',
            total_ttc: 'Total All Tax Included',
            empty: 'Empty cart',
            included_delivery_fee: 'Delivery fees included',
            to_cart_button: 'Go to cart',
            reset_cart_button: 'Delete cart'
        }
    }
};

export default en;
