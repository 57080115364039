import { Link as RouterLink } from 'react-router-dom';
import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {
  disabledLink?: boolean;
  color?: string
}

export default function Logo({ disabledLink = false, color = 'inherit', sx}: Props) {
  const logo = (
    <Box sx={{ width: 60, height: 60, ...sx }}>
      <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg"  width="100%" height="100%" viewBox="0 0 182.78 70.68">
        <defs>
        </defs>
          <g id="Groupe_734" data-name="Groupe 734">
              <g fill={color} id="Groupe_733" data-name="Groupe 733">
                  <path  id="Tracé_388" data-name="Tracé 388" d="M31.87,55.89c0,1.52-.25,1.77-1.68,1.77H2.53c-1.43,0-1.76-.25-1.76-1.77v-54C.77.33,1.1.08,2.53.08H30.1c1.68,0,2.1.17,2.1.92A3.66,3.66,0,0,1,32,2.18l-1.6,5.63a1.71,1.71,0,0,1-1.93,1.34H12.12V23H26.41c1.51,0,1.76.25,1.76,1.76v5.55c0,1.51-.34,1.77-1.76,1.77H12.12V48.58h18c1.52,0,1.77.25,1.77,1.77Z"/>
                  <path  id="Tracé_389" data-name="Tracé 389" d="M67,53.89s-7.28-13-8.84-15.84c2.45-1.28,6.75-3.94,6.75-11.6C64.9,14,54.08,14,50,14H40.07c-1.4,0-1.9.5-1.9,1.9V55.32c0,1.41.5,1.9,1.9,1.9h5.7c1.41,0,1.91-.5,1.91-1.9V39.89h1.94L57.72,56c.88,1.69,1.38,2.25,2,2.25l1-.14,5.17-1.48c.75-.23,1.68-.5,1.68-1.33A3.1,3.1,0,0,0,67,53.89ZM55.39,26.58c0,4.73-2.37,5.63-6.74,5.63h-1V21.69h1.64c3.56,0,6.07.35,6.07,4.89Z"/>
                  <path  id="Tracé_390" data-name="Tracé 390" d="M127,14.43h-9.5c-1.28,0-2,.31-2,1.91V55.75c0,1.39.51,1.91,1.91,1.91h9.37c11.19,0,18.64-3.72,18.64-21.83C145.47,18,138.41,14.43,127,14.43Zm8.51,21.28c0,12-2.74,14.09-8.88,14.09h-1.59V22.29h1.59c5.89,0,8.88,2.08,8.88,13.42Z"/>
                  <path  id="Tracé_391" data-name="Tracé 391" d="M182,55.89c0,1.52-.25,1.77-1.68,1.77H152.66c-1.42,0-1.76-.25-1.76-1.77v-54c0-1.51.34-1.76,1.76-1.76h27.58c1.68,0,2.1.17,2.1.92a3.67,3.67,0,0,1-.26,1.18l-1.6,5.63a1.7,1.7,0,0,1-1.93,1.34h-16.3V23h14.29c1.51,0,1.76.25,1.76,1.76v5.55c0,1.51-.33,1.77-1.76,1.77H162.25V48.58h18c1.51,0,1.76.25,1.76,1.77Z"/>
                  <path  id="Tracé_392" data-name="Tracé 392" d="M144.35,0H39.85a1.85,1.85,0,0,0-1.68,1.21L36,7.75a1,1,0,0,0,1,1.41H144.35a1.39,1.39,0,0,0,1.37-1.37V1.36A1.38,1.38,0,0,0,144.35,0Z"/>
                  <path  id="Rectangle_981" data-name="Rectangle 981" d="M.94,62h180.9a.94.94,0,0,1,.94,1h0v6.74a.94.94,0,0,1-.94,1H1A1,1,0,0,1,0,69.74V63A.94.94,0,0,1,.94,62Z"/>
                  <path  id="Tracé_393" data-name="Tracé 393" d="M105.11,20.28A20.84,20.84,0,0,0,89.91,14a21.15,21.15,0,0,0-15.33,6.27,21.38,21.38,0,0,0-.31,30.24c.31.32.63.62,1,.92a21.88,21.88,0,0,0,29.85-.71,20.42,20.42,0,0,0,6.4-15.17A20.79,20.79,0,0,0,105.11,20.28ZM89.69,47.79a12.35,12.35,0,0,1,.15-24.7,11.48,11.48,0,0,1,8.64,3.54,12.72,12.72,0,0,1,0,17.61,11.8,11.8,0,0,1-8.75,3.55Z"/>
              </g>
          </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/" aria-label='Logo Erode'>{logo}</RouterLink>;
}
