import {
  Box,
  Stack,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  IconButton,
  TableContainer,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
// utils
import { fCurrency } from 'src/utils/formatNumber';
// @types
import { CartItem } from 'src/@types/checkout';
// components
import Image from 'src/components/commons/Image';
import Iconify from 'src/components/commons/Iconify';
import { TableHeadCustom } from 'src/components/table';
import useLocales from 'src/hooks/useLocales';
import { useEffect, useState } from 'react';
// services
import ModelService from 'src/services/modelService';
import { useDispatch } from 'src/redux/store';
import { deleteCart, editCart } from 'src/redux/slices/checkout/actions';


// ----------------------------------------------------------------------

type Props = {
  products: CartItem[];
};

export default function CheckoutProductList({
  products,
}: Props) {
  const {translate} = useLocales();
  const TABLE_HEAD = [
    { id: 'product', label: translate('view.shop_checkout.section.cart_list.table_head.product') },
    { id: 'quantity', label: translate('view.shop_checkout.section.cart_list.table_head.quantity') },
    { id: 'totalPrice', label: translate('view.shop_checkout.section.cart_list.table_head.total_price'), align: 'right' },
    { id: '' },
  ];

  return (
    <TableContainer sx={{ minWidth: 720 }}>
      <Table>
        <TableHeadCustom headLabel={TABLE_HEAD} />

        <TableBody>
          {products.map((row, index) => (
            <CheckoutProductListRow
              index={index}
              key={row.id}
              row={row}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

// ----------------------------------------------------------------------

type CheckoutProductListRowProps = {
  index: number;
  row: CartItem;
};

function CheckoutProductListRow({
  index,
  row,
}: CheckoutProductListRowProps) {
  const { model, quantity } = row;
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const [totalPrice, setTotalPrice] = useState('');
  const [cartItemQuantity, setCartItemQuantity] = useState(quantity)
  const quantityOptions = [100, 250, 500, 750, 1000, 5000, 10000];

  // Control the select component change
  const handleSetQuantity = async (e:SelectChangeEvent) => {
    try{
      const {data:response} = await ModelService.getPrice({
        pampilleId: model.pampille.id,
        finitionId: model.finition.id,
        quantity: parseInt(e.target.value),
        marking: model.marking,
        punchCreation: model.punchCreation
      })

      setTotalPrice(response.price)
      dispatch(editCart({index: index, item: {...row, quantity: parseInt(e.target.value), model: {
        ...model, price: response.price, unit_price: response.price / parseInt(e.target.value)
      }}}))
    }catch(err){
      console.log(err)
    }
    setCartItemQuantity(parseInt(e.target.value))
  }

  // Delete a cart item from cart array
  const handleDeleteCart = (cartItemId: string) => {
    dispatch(deleteCart(cartItemId));
  };

  // Hook to edit cart quantity and price on select change
  useEffect(() => {
    const fetchData = async () => {
      try{
        const {data:response} = await ModelService.getPrice({
          pampilleId: model.pampille.id,
          finitionId: model.finition.id,
          quantity: cartItemQuantity,
          marking: model.marking,
          punchCreation: model.punchCreation
        })
        setTotalPrice(response.price)
        dispatch(editCart({index: index, item: {...row, quantity: cartItemQuantity, model: {
          ...model, price: response.price, unit_price: response.price / cartItemQuantity
        }}}))
      }catch(err){
        console.log(err)
      }
    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TableRow>
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Image
          alt="Product image"
          src={model.cover}
          sx={{ width: 64, height: 64, borderRadius: 1.5, mr: 2 }}
        />

        <Stack spacing={0.5}>
          <Typography noWrap variant="subtitle2" sx={{ maxWidth: 240 }}>
            {model.pampille.name}
          </Typography>

          <Stack direction="row" alignItems="center">
            <Typography variant="body2">
              <Box component="span" sx={{ color: 'text.secondary' }}>
                {translate('commons.pampille.finish')}:&nbsp;
              </Box>
              {model.finition.name}
            </Typography>
          </Stack>
        </Stack>
      </TableCell>
      <TableCell>
        <Select
            labelId="quantity-select-label"
            id="quantity-select"
            value={cartItemQuantity}
            onChange={(e:any) => handleSetQuantity(e)}
            defaultValue={100}
        >
          {quantityOptions.map((value, index) => {
            return <MenuItem value={value} key={index}>{value}</MenuItem>
          })}
        </Select>
      </TableCell>

      <TableCell align="left">{fCurrency(parseFloat(totalPrice))}</TableCell>

      <TableCell align="right">
        <IconButton onClick={() => handleDeleteCart(row.id)}>
          <Iconify icon={'eva:trash-2-outline'} width={20} height={20} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}