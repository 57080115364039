import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Divider, Typography, Stack, DialogProps } from '@mui/material';
import { useDispatch } from 'src/redux/store';
import { resetCart } from 'src/redux/slices/checkout/actions';
import { PATH_SHOP } from 'src/routes/paths';
import Iconify from 'src/components/commons/Iconify';
import { DialogAnimate } from 'src/components/animate';
// assets
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

const DialogStyle = styled(DialogAnimate)(({ theme }) => ({
  '& .MuiDialog-paper': {
    margin: 0,
    [theme.breakpoints.up('md')]: {
      maxWidth: 'calc(100% - 48px)',
      maxHeight: 'calc(100% - 48px)',
    },
  },
}));

/* Checkout Order Complete Component.
*  Dialog card toggled on completed checkout.
**/
export default function CheckoutOrderComplete({ open }: DialogProps) {
  const navigate = useNavigate();
  const {translate} = useLocales();
  const dispatch = useDispatch();

  const handleResetStep = () => {
    dispatch(resetCart());
    navigate(PATH_SHOP.root);
  };

  return (
    <DialogStyle fullScreen open={open}>
      <Box sx={{ p: 4, maxWidth: 480, margin: 'auto' }}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4" paragraph>
            {translate('view.shop_checkout.section.order_complete.title')}
          </Typography>

          <Typography align="left" paragraph>
            {translate('view.shop_checkout.section.order_complete.thanks')}
            <Link href="#">01dc1370-3df6-11eb-b378-0242ac130002</Link>
          </Typography>

          <Typography gutterBottom align="left" sx={{ color: 'text.secondary' }}>
            {translate('view.shop_checkout.section.order_complete.notice')}
          </Typography>
          <Typography gutterBottom align="left" sx={{ color: 'text.secondary' }}>
            {translate('view.shop_checkout.section.order_complete.question')}
          </Typography>
          <Typography gutterBottom align="left" sx={{ color: 'text.secondary' }}>
            {translate('view.shop_checkout.section.order_complete.greetings')}
          </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Stack
          direction={{ xs: 'column-reverse', sm: 'row' }}
          justifyContent="space-between"
          spacing={2}
        >
          <Button
            color="inherit"
            onClick={handleResetStep}
            startIcon={<Iconify icon={'eva:arrow-ios-back-fill'} />}
          >
            {translate('view.shop_checkout.section.order_complete.back_to_shop')}
          </Button>
          <Button
            variant="contained"
            startIcon={<Iconify icon={'ant-design:file-pdf-filled'} />}
            onClick={handleResetStep}
          >
            {translate('view.shop_checkout.section.order_complete.download_button_text')}
          </Button>
        </Stack>
      </Box>
    </DialogStyle>
  );
}
