
import { ReactNode } from 'react';
import { Link, Stack, LinkProps } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import navConfig from './MenuConfig';
import {MenuProps} from './type';
import useLocales from 'src/hooks/useLocales';

interface RouterLinkProps extends LinkProps {
  component?: ReactNode;
  to?: string;
  end?: boolean;
}

const LinkStyle = styled(Link)<RouterLinkProps>(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: 'inherit',
  marginRight: theme.spacing(0),
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
  '&:hover': {
    opacity: 0.48,
    textDecoration: 'none',
  }
}));


export default function MenuDesktop({isOffset}:MenuProps) {

  const {translate} = useLocales();

  return (
    <Stack direction="row" spacing={5}>
      {navConfig.map((link, index) => (
          <LinkStyle
              key={index} to={link.path} component={RouterLink} sx={{
              ...(isOffset && { color: 'inherit' }), fontWeight: 500,
            }}>
              {translate(link.locale)}
          </LinkStyle>
      ))}
    </Stack>
  );
}



