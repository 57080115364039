import { styled, useTheme} from '@mui/material/styles';
import { Box, Stack, Container, AppBar, Toolbar, Button } from '@mui/material';
import useOffSetTop from '../../../hooks/useOffSetTop';
import cssStyles from '../../../utils/cssStyles';
import { HEADER } from '../../../config';
import Logo from '../../../components/commons/Logo';
import MenuDesktop from './MenuDesktop';
import LanguagePopover from './LanguagePopover';
import AccountPopover from './AccountPopover';
import CartDrawer from 'src/components/cart/drawer';
import useAuth from '../../../hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import { PATH_AUTH } from 'src/routes/paths';
import { Link as RouterLink } from 'react-router-dom';
import useResponsive from 'src/hooks/useResponsive';
import MenuMobile from './MenuMobile';

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

export default function MainHeader() {
  const { user } = useAuth();
  const { translate } = useLocales();
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'md');

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: theme.palette.background.dark}}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
          }),
        }}
      >
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Logo color='white' />
          <Box sx={{ flexGrow: 1 }} />
            {isDesktop ? <MenuDesktop isOffset={isOffset}/> : <MenuMobile isOffset={isOffset} />}
            <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 5 }}>
              <CartDrawer />
              {isDesktop && 
              <>
                {user ? <AccountPopover /> : 
                  <>
                    <RouterLink to={PATH_AUTH.login}>
                      <Button 
                      variant="outlined"
                      size="small"
                      >
                        {translate('layout.navigation.login_button')}
                      </Button>
                    </RouterLink>
                  </>
                }
              </>
              }
              
              <LanguagePopover />
            </Stack>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
