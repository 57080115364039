// @mui
import {
    Box,
    Card,
    Stack,
    Divider,
    CardHeader,
    Typography,
    CardContent,
    TextField,
    InputAdornment,
    Button,
    TableRow,
    TableCell,
    TableContainer,
    Table,
    TableBody,
  } from '@mui/material';
import { fCurrency } from 'src/utils/formatNumber';
import useLocales from 'src/hooks/useLocales';
import { CartItem } from 'src/@types/checkout';
import { TableHeadCustom } from 'src/components/table';
  
  // ----------------------------------------------------------------------
  
  type Props = {
    cart?: CartItem[];
    total: number;
    discount?: number;
    subtotal: number;
    shipping?: number;
    onApplyDiscount?: (discount: number) => void;
    enableDiscount?: boolean;
  };
  
  type CheckoutSummaryCartListProps = {
    cart: CartItem[];
  };
  
  function CheckoutSummaryCartList({
    cart,
  }: CheckoutSummaryCartListProps) {
    const {translate} = useLocales();
    const TABLE_HEAD = [
      { id: 'product', label: translate('view.shop_checkout.section.cart_list.table_head.product') },
    ];
  
    return (
      <TableContainer sx={{ minWidth: 720 }}>
        <Table>
          <TableHeadCustom headLabel={TABLE_HEAD} />
  
          <TableBody>
            {cart.map((cartItem: CartItem, index: number) => {
              return <TableRow key={index}>
                <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                  <Stack spacing={0.5}>
                    <Typography noWrap variant="subtitle2" sx={{ maxWidth: 240 }}>
                      {cartItem.model.pampille.name}
                    </Typography>
  
                    <Stack direction="row" alignItems="center">
                      <Typography variant="body2">
                        <Box component="span" sx={{ color: 'text.secondary' }}>
                          {translate('layout.cart.finish')}:&nbsp;
                        </Box>
                        {cartItem.model.finition.name}
                      </Typography>
                    </Stack>
                    <Typography variant="body2">{translate('layout.cart.price')} : {cartItem.model.price} €</Typography>
                  </Stack>
                </TableCell>
              </TableRow>
            })} 
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  
  export default function CheckoutSummary({
    cart,
    total,
    subtotal,
    shipping,
    discount,
    onApplyDiscount,
    enableDiscount = false,
  }: Props) {
    const {translate} = useLocales();
    
    return (
      <Card sx={{mb: 3}}>
        <CardHeader title={translate('view.shop_checkout.section.summary_card.title')}/>
  
        <CardContent>
          {cart && <CheckoutSummaryCartList cart={cart} />}

          <Stack  sx={{px: '24px'}} spacing={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {translate('view.shop_checkout.section.summary_card.subtotal')}
              </Typography>
              <Typography variant="subtitle2">{fCurrency(subtotal)}</Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Discount
              </Typography>
              <Typography variant="subtitle2">{discount ? fCurrency(-discount) : '-'}</Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {translate('view.shop_checkout.section.summary_card.shipping')}
              </Typography>
              <Typography variant="subtitle2">
                {/* {shipping ? fCurrency(shipping) : displayShipping} */}

                {fCurrency(shipping!)}
              </Typography>
            </Stack>
  
            <Divider />
  
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="subtitle1">{translate('view.shop_checkout.section.summary_card.total_ht')}</Typography>
              <Box sx={{ textAlign: 'right' }}>
                <Typography variant="subtitle1" sx={{ color: 'primary.main' }}>
                  {fCurrency(subtotal)}
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="subtitle1">{translate('view.shop_checkout.section.summary_card.total_ttc')}</Typography>
              <Box sx={{ textAlign: 'right' }}>
                <Typography variant="subtitle1" sx={{ color: 'error.main' }}>
                  {fCurrency(total)}
                </Typography>
              </Box>
            </Stack>
            {enableDiscount && onApplyDiscount && (
              <TextField
                fullWidth
                placeholder="Code promo"
                value=""
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button onClick={() => onApplyDiscount(0)} sx={{ mr: -0.5 }}>
                        Apply
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Stack>
        </CardContent>
      </Card>
    );
  }
  