import { Box, Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import MainHeader from '../main/header';

export default function StudioLayout() {

    return (
        <Stack sx={{ minHeight: 1 }}>
            <MainHeader />
            <Outlet />
            <Box sx={{ flexGrow: 1 }} />
        </Stack>
    );
}