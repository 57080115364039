import { AnimatePresence, m } from 'framer-motion';
import { useState, useEffect } from 'react';
import { alpha, styled } from '@mui/material/styles';
import { Stack, Divider, Backdrop, Typography, Button, IconButton } from '@mui/material';
import cssStyles from '../../../utils/cssStyles';
import { NAVBAR } from '../../../config';
import Iconify from '../../commons/Iconify';
import Image from '../../commons/Image';
import ToggleButton from './ToggleButton';
import { varFade } from '../../animate';
import {useDispatch, useSelector} from 'src/redux/store';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useNavigate } from 'react-router-dom';
import { PATH_SHOP } from 'src/routes/paths';
import { getCart, onGotoStep, resetCart } from 'src/redux/slices/checkout/actions';
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
import { CartItem } from 'src/@types/checkout';

const RootStyle = styled(m.div)(({ theme }) => ({
  ...cssStyles(theme).bgBlur({ color: theme.palette.background.paper, opacity: 0.92 }),
  top: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  position: 'fixed',
  overflow: 'auto',
  height: '100vh',
  color: theme.palette.text.primary,
  flexDirection: 'column',
  margin: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  zIndex: theme.zIndex.drawer + 3,
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  boxShadow: `-24px 12px 32px -4px ${alpha(
    theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
    0.16
  )}`,
}));

export default function CartDrawer() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const navigate = useNavigate();
  const {translate} = useLocales();
  const { cart, total_ht, total_ttc, shipping } = useSelector((state) => state.checkout);
  const isDesktop = useResponsive('up', 'md');
  const drawer_width = isDesktop ? 400 : '60%';
  
  const varSidebar = varFade({
    distance: NAVBAR.BASE_WIDTH,
    durationIn: 0.32,
    durationOut: 0.32,
  }).inRight;

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [open]);

  useEffect(() => {
    if (isMountedRef.current) {
      dispatch(getCart(cart));
    }
  }, [dispatch, isMountedRef, cart]);

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToCart = () => {
    navigate(PATH_SHOP.checkout, {replace: true})
    dispatch(onGotoStep(0));
    setOpen(false);
  }

  const handleResetCart = () => {
    dispatch(resetCart())
  }

  return (
    <>
      <Backdrop
        open={open}
        onClick={handleClose}
        sx={{ background: 'transparent', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      />

      <ToggleButton open={open} onToggle={handleToggle} />
      <AnimatePresence>
        {open && (
            <RootStyle sx={{width: drawer_width}} {...varSidebar}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ py: 2, pr: 1, pl: 2.5 }}
              >
                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                  {translate('layout.cart.title')}
                </Typography>

                <IconButton onClick={handleClose}>
                  <Iconify icon={'eva:close-fill'} width={20} height={20} />
                </IconButton>
              </Stack>

              <Divider sx={{ borderStyle: 'dashed' }} />
              <Stack spacing={3} sx={{ p: 3, height: '55%', overflow: 'auto'}}>
                {cart.map((cartItem:CartItem, index:number) => {
                  return <Stack key={index} direction='row' spacing={1.5}>
                      <Image
                        disabledEffect
                        alt={cartItem.model.pampille.name}
                        src={cartItem.model.cover}
                        sx={{ borderRadius: 1.5, width: 48, height: 48, mr: 2 }}
                      />
                      <Stack>
                        <Typography variant='subtitle2'>{cartItem.model.pampille.name}</Typography>
                        <Typography variant='body2'>{translate('layout.cart.quantity')} : {cartItem.quantity}</Typography>
                        <Typography variant='body2'>{translate('commons.pampille.finish')} : {cartItem.model.finition.name} </Typography>
                        <Typography variant='body2'>{translate('layout.cart.total_ht')} : {parseFloat(cartItem.model.price).toFixed(2)} €</Typography>
                      </Stack>
                    </Stack>
                  })}
              </Stack>
              <Stack spacing={2} sx={{ p: 3, height: '15%'}}>
                {total_ht === 0 ? <Typography variant='subtitle2'> {translate('layout.cart.empty')} </Typography> :
                <>
                  <Typography variant='subtitle2' color={'primary.main'}> {translate('layout.cart.total_ht')} : {total_ht.toFixed(2)} € </Typography>
                  <Typography variant='subtitle2'> {translate('layout.cart.total_ttc')} : {total_ttc.toFixed(2)} € * </Typography>
                  <Typography variant='body2'> * {translate('layout.cart.included_delivery_fee')} : {shipping} € </Typography>

                </>
                }
                
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  color='primary'
                  sx={{
                    fontSize: 14,
                  }}
                  onClick={handleToCart}
                >
                  {translate('layout.cart.to_cart_button')}
                </Button>

                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  color='warning'
                  sx={{
                    fontSize: 14,
                  }}
                  onClick={handleResetCart}
                >
                  {translate('layout.cart.reset_cart_button')}
                </Button>
              </Stack>
            </RootStyle>
        )}
      </AnimatePresence>
    </>
  );
}
