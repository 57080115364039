import jwtDecode from 'jwt-decode';
import { resetCart } from 'src/redux/slices/checkout/actions';
import { dispatch } from 'src/redux/store';
import { PATH_AUTH } from '../routes/paths';
import axios from './axios';

// Check if token is still valid
const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// Remove token when expired and reset current cart
const handleTokenExpired = (exp: number) => {
  let expiredTimer;

  const currentTime = Date.now();
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    localStorage.removeItem('accessToken');
    dispatch(resetCart());
    window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

// Register the token in the axios header instance for later HTTP requests
const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common['x-access-token'] = `${accessToken}`;
    const { exp } = jwtDecode<{ exp: number }>(accessToken);
    handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common['x-access-token'];
  }
};

export { isValidToken, setSession };
