import useAuth from '../hooks/useAuth';
import { Navigate } from 'react-router-dom';
import { PATH_PAGE } from '../routes/paths';

type RoleGuardProp = {
  hasContent?: boolean;
  roles?: string[];
  children: React.ReactNode;
};

export default function RoleGuard({ hasContent, roles, children }: RoleGuardProp) {
  const { user } = useAuth();
  
  const currentRole = user?.role;

  if (typeof roles !== 'undefined' && !roles.includes(currentRole)) {
    return hasContent ? (
      <Navigate to={PATH_PAGE.home} />
    ) : null;
  }

  return <>{children}</>;
}
