import { Box, Container, Stack, styled, Typography, useTheme } from "@mui/material";
import { Outlet, useLocation} from "react-router-dom";
import MainHeader from './header';
import MainFooter from './footer'
import Logo from '../../components/commons/Logo';
import useLocales from '../../hooks/useLocales';

const RootStyle = styled('div')(({ theme }) => ({
    height:'100%',
    background: theme.palette.background.neutral,
    paddingTop: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(11),
    },
}));

export default function MainLayout() {

    const { pathname } = useLocation();
    const { translate } = useLocales();
    const isHome = pathname === '/';
    const theme = useTheme();

    return (
        <Stack sx={{ minHeight: 1 }}>
            <MainHeader />
            <RootStyle>
                <Outlet />
            </RootStyle>
            <Box sx={{ background: theme.palette.background.neutral, flexGrow: 1 }} />
            {isHome ? (
                <MainFooter />
            ) : (
                <Box
                    sx={{
                        py: 5,
                        textAlign: 'center',
                        position: 'relative',
                        bgcolor: 'background.default',
                    }}
                >
                <Container>
                    <Logo color={theme.palette.background.dark} sx={{ mb: 1, mx: 'auto' }} />
                    <Typography variant="caption" component="p">
                    © {translate('layout.footer.copyright')}
                    </Typography>
                </Container>
                </Box>
            )}
        </Stack>
    );
}