import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import { CartItem, CheckoutState } from 'src/@types/checkout';

const initialState: CheckoutState = {
    orderComplete: false,
    activeStep: 0,
    cart: [],
    discount: 0,
    subtotal: 0,
    total: 0,
    total_ht: 0,
    total_ttc: 0,
    shipping: 25,
    billing: null,
};

const slice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    // CHECKOUT
    getCart(state, action) {
      const cart = action.payload;
      const subtotal = sum(cart.map((cartItem: CartItem) => parseFloat(cartItem.model.price)));
      const shipping = state.shipping
      const billing = cart.length === 0 ? null : state.billing;
      const discount = cart.length === 0 ? 0 : state.discount;

      state.cart = cart;
      state.shipping = shipping;
      state.billing = billing;
      state.subtotal = subtotal;

      state.total_ht = subtotal;
      state.total_ttc = (state.total_ht - discount) + ((state.total_ht - discount) * 0.2) + shipping
      state.total = (subtotal - discount) + ((subtotal - discount) * 0.2) +  shipping
    },

    editCart(state, action){
      state.cart[action.payload.index] = action.payload.item;
    },

    addCart(state, action) {
      const cartItem:CartItem = action.payload;
      state.cart = [...state.cart, cartItem];
    },
    
    deleteCart(state, action) {
      const updateCart = state.cart.filter((item) => item.id !== action.payload.toString());
      state.cart = updateCart;
    },

    resetCart(state) {
      state.activeStep = 0;
      state.cart = [];
      state.total = 0;
      state.subtotal = 0;
      state.shipping = 25;
      state.billing = null;
    },

    onBackStep(state) {
      state.activeStep -= 1;
    },

    onNextStep(state) {
      state.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.activeStep = goToStep;
    },

    increaseQuantity(state, action) {
      const cartItem = action.payload;
      const updateCart = state.cart.map((updatedItem) => {
        if (updatedItem.id === cartItem.id) {
          return {
            ...updatedItem,
            quantity: cartItem.quantity,
            model: {
              ...updatedItem.model,
              price: parseFloat(cartItem.model.price),
              unit_price: cartItem.model.unit_price
            }
          };
        }
        return cartItem;
      });
      state.cart = updateCart;
    },

    setCartItemPrice(state, action){
      const cartItem = action.payload;
      const updateCart = state.cart.map((updatedItem) => {
        if (updatedItem.id === cartItem.id) {
          return {
            ...updatedItem,
            quantity: action.payload.quantity,
            model: {
              ...updatedItem.model,
              price: parseFloat(cartItem.model.price),
              unit_price: cartItem.model.unit_price
            }
          };
        }
        return cartItem;
      });
      state.cart = updateCart;
    },

    createBilling(state, action) {
      state.billing = action.payload;
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.shipping = shipping;
      state.total = state.subtotal + shipping;
      state.total_ht = state.subtotal + shipping;
      state.total_ttc = state.total_ht + state.total_ht * 0.2
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.discount = discount;
      state.total_ttc = state.subtotal - discount;
    },

    setOrderComplete(state, action) {
      state.orderComplete = action.payload
    }
  },
});

export const checkoutSlice = slice;
export default slice.reducer;