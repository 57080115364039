import {checkoutSlice} from './slice'

export const {
  getCart,
  addCart,
  resetCart,
  editCart,
  deleteCart,
  increaseQuantity,
  setCartItemPrice,
  applyDiscount,
  createBilling,
  onBackStep,
  onGotoStep,
  onNextStep,
  applyShipping,
  setOrderComplete
} = checkoutSlice.actions;