import axiosInstance from "src/utils/axios";

const BASE_URL = process.env.REACT_APP_HOST_API

export default class HTTPService {
    end_point: string;

    constructor(end_point: string) {
        this.end_point = end_point;
    }

    getAll(params?: any) {
        return axiosInstance.get(BASE_URL + this.end_point, params).then(response => response);
    }

    getOne(id: string) {
        return axiosInstance.get(BASE_URL + this.end_point + `/${id}`).then(response => response);
    }

    post(payload: any) {
        return axiosInstance.post(BASE_URL + this.end_point, payload).then(response => response);
    }

    put(id: string, payload: any) {
        return axiosInstance.put(BASE_URL + this.end_point + `/${id}`, payload).then(response => response);
    }

    delete(id: string) {
        return axiosInstance.delete(BASE_URL + this.end_point + `/${id}`).then(response => response);
    }
}