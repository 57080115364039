import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Grid, Card, Button, CardHeader, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'src/redux/store';

import Iconify from 'src/components/commons/Iconify';
import Scrollbar from 'src/components/commons/Scrollbar';
import EmptyContent from 'src/components/commons/EmptyContent';
import CheckoutProductList from './CheckoutProductList';
import { PATH_AUTH, PATH_SHOP } from 'src/routes/paths';
import { onNextStep, applyDiscount} from 'src/redux/slices/checkout/actions';
import useLocales from 'src/hooks/useLocales';
import CheckoutSummary from './CheckoutSummary';
import useAuth from 'src/hooks/useAuth';

/* Checkout Cart Card Component.
*  Displays the list of items in the cart.
**/
export default function CheckoutCartCard() {
  const {translate} = useLocales();

  // hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {isAuthenticated} = useAuth();

  // redux
  const { cart, total, shipping, subtotal, discount } = useSelector((state) => state.checkout);
  const totalItems = cart.length;
  const isEmptyCart = cart.length === 0;

  const handleApplyDiscount = (value: number) => {
    dispatch(applyDiscount(value));
  };

  const handleNextStep = async  () => {
    if(isAuthenticated){
      dispatch(onNextStep());
    }else{
      navigate(PATH_AUTH.login);
    }
  };
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Button
            color="inherit"
            component={RouterLink}
            to={PATH_SHOP.root}
            startIcon={<Iconify icon={'eva:arrow-ios-back-fill'} />}
          >
            {translate('view.shop_checkout.continue_shopping')}
        </Button>
      </Grid>
      <Grid item xs={12} md={8}>
        <Card sx={{ mb: 3 }}>
          <CardHeader
            title={
              <Typography variant="h6">
                {translate('view.shop_checkout.section.cart_list.title')}
                <Typography component="span" sx={{ color: 'text.secondary' }}>
                  &nbsp;({totalItems} {translate('view.shop_checkout.section.cart_list.items')})
                </Typography>
              </Typography>
            }
            sx={{ mb: 3 }}
          />

          {!isEmptyCart ? (
            <Scrollbar>
              <CheckoutProductList
                products={cart}
              />
            </Scrollbar>
          ) : (
            <EmptyContent
              title={translate('view.shop_checkout.section.cart_list.empty_title')}
              description={translate('view.shop_checkout.section.cart_list.empty_description')}
              img="/assets/illustrations/illustration_empty_cart.svg"
            />
          )}
        </Card>
      </Grid>

      <Grid item xs={12} md={4}>
        <CheckoutSummary
          total={total}
          shipping={shipping}
          subtotal={subtotal}
          discount={discount}
          enableDiscount={true}
          onApplyDiscount={handleApplyDiscount}
        />
        <Button
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={cart.length === 0}
          onClick={handleNextStep}
        >
          {translate('view.shop_checkout.view_title')}
        </Button>
      </Grid>
    </Grid>
  );
}
