import { alpha } from '@mui/material/styles';
import { Tooltip, Badge } from '@mui/material';
import Iconify from '../../commons/Iconify';
import { IconButtonAnimate } from '../../animate';
import {useSelector} from 'src/redux/store';


type Props = {
  open: boolean;
  onToggle: VoidFunction;
};

export default function ToggleButton({ open, onToggle }: Props) {
  const { cart } = useSelector((state) => state.checkout);
  const totalItems = cart.length;

  return (
      <Tooltip title="Panier" placement="bottom">
        <IconButtonAnimate
          color="primary"
          onClick={onToggle}
          sx={{
            p: 1.25,
            transition: (theme) => theme.transitions.create('all'),
            '&:hover': {
              color: 'primary.main',
              bgcolor: (theme) =>
                alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
            },
          }}
        > 
        <Badge badgeContent={totalItems} color="warning" max={99}>
          <Iconify icon="eva:shopping-cart-fill" width={20} height={20} />
        </Badge>
        </IconButtonAnimate>
      </Tooltip>
  );
}
