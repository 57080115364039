// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from 'src/components/commons/SvgIconStyle';

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  pampille: getIcon('ic_ecommerce'),
  orders: getIcon('ic_cart'),
  users: getIcon('ic_user')
};

const navConfig = [

  {
    subheader: 'Gestion',
    items: [
      // Pampilles
      {
        title: 'Pampilles',
        path: PATH_DASHBOARD.pampilles.root,
        icon: ICONS.pampille,
        children: [
          { title: 'Liste des pampilles', path: PATH_DASHBOARD.pampilles.list },
        ],
      },
      {
        title: 'Commandes',
        path: PATH_DASHBOARD.orders.root,
        icon: ICONS.orders,
        children: [
          { title: 'Liste des commandes', path: PATH_DASHBOARD.orders.list },
        ],
      },
      {
        title: 'Utilisateurs',
        path: PATH_DASHBOARD.users.root,
        icon: ICONS.users,
        children: [
          { title: 'Liste des utilisateurs', path: PATH_DASHBOARD.users.list },
        ],
      },
    ],
  },
];

export default navConfig;
