import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, Stack } from '@mui/material';
import SocialsButton from '../../../components/commons/SocialsButton';
import { PATH_PAGE, PATH_SHOP } from '../../../routes/paths';
import useLocales from 'src/hooks/useLocales';
import Logo from 'src/components/commons/Logo';
import { isExternalLink } from 'src/utils/link';

const LINKS = [
  {
    headline: 'Erode Pampille',
    children: [
      { name: 'layout.footer.our_products', href: PATH_SHOP.root },
      { name: 'layout.footer.contact_us', href:  PATH_PAGE.contact  },
    ], 
  },
  {
    headline: 'layout.footer.legal_notice',
    children: [
      { name: 'layout.footer.legal_notice', href: PATH_PAGE.legal_notice },
      { name: 'layout.footer.privacy_policy', href: PATH_PAGE.cgv },
      { name: 'Certificat ISO 9001:2015', href: 'https://erode.fr/fileadmin/user_upload/Accueil/iso_9001_erode.pdf'}
    ],
  },
  {
    headline: 'layout.navigation.contact',
    children: [
      { name : '4 Chemin des Remises, 60410 Verberie, France', href: '#'},
      { name : 'Tél : 03 44 40 54 84', href: '#'},
      { name : 'Fax : 03 44 40 53 17', href: '#'},
      { name: 'contact@erode.fr', href: '#' },
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.dark,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {

  const {translate} = useLocales();
  return (
    <RootStyle>
      <Divider />
      <Container sx={{ pt: 10, pb: 5 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={4} md={3}>
            <Logo justifyContent='center' alignContent='center' color='white' 
            sx={{ width: '100%', textAlign:'center', maxWidth: '100%' }}/>

            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'center' }}
              sx={{ mt: 2, mb: { xs: 5, md: 0 } }}
            >
              <SocialsButton sx={{ mr: 0.5 }} />
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack
              spacing={5}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
            >
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography color='white' component="p" variant="overline">
                    {translate(list.headline)}
                  </Typography>
                  {list.children.map((link) => (
                    <>
                    {isExternalLink(link.href) ? (
                      <Link
                        href={link.href}
                        key={link.name}
                        color="white"
                        variant="body2"
                        component={Link}
                        sx={{ display: 'block' }}
                        target='_blank'
                      >
                        {translate(link.name)}
                      </Link>
                      ):(
                        <Link
                          to={link.href}
                          key={link.name}
                          color="white"
                          variant="body2"
                          component={RouterLink}
                          sx={{ display: 'block' }}
                        >
                          {translate(link.name)}
                        </Link>
                      )}
                    </>
                  ))}
                  {list.headline === 'layout.footer.legal_notice' && 
                    <Typography
                    color='white'
                    component="p"
                    variant="body2"
                    sx={{
                      mt: 5,
                      pb: 5,
                      fontSize: 13,
                      textAlign: { xs: 'center', md: 'left' },
                    }}
                  >
                    © ERODE 2022
                  </Typography>}
                  {list.headline === 'nav.contact' && 
                   <Typography color='white' component="p" variant="body2">
                        4 Chemin des Remises <br/> 60410 Verberie France
                    </Typography>}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
