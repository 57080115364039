function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}


const ROOTS_SHOP = '/shop';
const ROOTS_AUTH = '/auth';
const ROOTS_ACCOUNT = '/myaccount';
const ROOTS_STUDIO = '/studio'
const ROOTS_DASHBOARD = '/erode_dashboard'

export const PATH_PAGE = {
  home: '/',
  cgv: '/cgv',
  legal_notice: '/legal_notice',
  company: '/our_company',
  contact: '/contact',
  email_confirmation:  (uuid: string) => `/email-confirmation/${uuid}`,
  page404: '/404',
};

export const PATH_SHOP = {
  root: '/shop',
  list: path(ROOTS_SHOP, '/products'),
  view: (id: number) => path(ROOTS_SHOP, `/products/${id}`),
  checkout: path(ROOTS_SHOP, '/checkout'),
};

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  forgottenPassword: path(ROOTS_AUTH, '/forgotten-password'),
  resetPassword: (uuid: string) => path(ROOTS_AUTH, `/reset-password/${uuid}`),
};

export const PATH_ACCOUNT = {
  root: ROOTS_ACCOUNT,
  models: path(ROOTS_ACCOUNT, '/models'),
  settings: path(ROOTS_ACCOUNT, '/settings'),
  shipping: path(ROOTS_ACCOUNT, '/shipping'),
  images: path(ROOTS_ACCOUNT, '/images'),
  billing: path(ROOTS_ACCOUNT, '/billing'),
  order: {
    list: path(ROOTS_ACCOUNT, '/orders'),
    view: (id: string) => path(ROOTS_ACCOUNT, `/orders/${id}`),
  }
}

export const PATH_STUDIO = {
  root: ROOTS_STUDIO,
}

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  pampilles: {
    root: path(ROOTS_DASHBOARD, '/pampilles'),
    list: path(ROOTS_DASHBOARD, '/pampilles/list'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/pampilles/edit/${id}`),
    create: path(ROOTS_DASHBOARD, '/pampilles/create')
  },
  orders: {
    root: path(ROOTS_DASHBOARD, '/orders'),
    list: path(ROOTS_DASHBOARD, '/orders/list'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/orders/detail/${id}`)
  },
  users: {
    root: path(ROOTS_DASHBOARD, '/users'),
    list: path(ROOTS_DASHBOARD, '/users/list'),
  }
}