import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Box,
  Stack,
  Dialog,
  Button,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// @types
import { OnCreateBilling } from 'src/@types/checkout';
import { countries } from 'src/_mock/countries';

import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from 'src/components/hook-form';
import useLocales from 'src/hooks/useLocales';

import AddressService from 'src/services/addressService';

// ----------------------------------------------------------------------

interface FormValuesProps {
    addressName: string;
    fullName: string;
    phoneNumber: string;
    address: string;
    postalCode: string;
    city: string;
    country: string;
}

type Props = {
  open: boolean;
  onClose: VoidFunction;
  onNextStep: VoidFunction;
  onCreateBilling: OnCreateBilling;
};

/* Billing address form. */
export default function CheckoutNewAddressForm({
  open,
  onClose,
  onNextStep,
  onCreateBilling,
}: Props) {

    const {translate} = useLocales();

    const NewAddressSchema = Yup.object().shape({
        addressName: Yup.string().required('Name is required'),
        fullName: Yup.string().required('Full Name is required'),
        phoneNumber: Yup.string().required('Phone is required'),
        address: Yup.string().required('Address is required'),
        city: Yup.string().required('City is required'),
        country: Yup.string().required('Country is required'),
        postalCode: Yup.string().required('Postal Code is required'),
    });

    const defaultValues = {
        addressName: '',
        fullName: '',
        address: '',
        phoneNumber: '',
        city: '',
        country: countries[73].label,
        postalCode: '',
    };

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(NewAddressSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = async (data: FormValuesProps) => {
        try {
            const {data:response} = await AddressService.post(data);
            
            onCreateBilling({
                id: response.id,
                addressName: data.addressName,
                fullName: data.fullName,
                address: data.address,
                phoneNumber: data.phoneNumber,
                city: data.city,
                postalCode: data.postalCode,
                country: data.country
            });
            onNextStep()
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
        <DialogTitle>{translate('view.shop_checkout.section.billing_address.form.add_button_text')}</DialogTitle>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
            <Stack spacing={3}>
                <Box
                sx={{
                    display: 'grid',
                    rowGap: 3,
                    columnGap: 2,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
                >   
                    <RHFTextField name="addressName" label={translate('view.shop_checkout.section.billing_address.form.address_name_label')}  />
                    <RHFTextField name="fullName" label={translate('view.shop_checkout.section.billing_address.form.full_name_label')}  />
                </Box>
                <Box
                sx={{
                    display: 'grid',
                    rowGap: 3,
                    columnGap: 2,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
                >   
                    <RHFTextField name="address" label={translate('view.shop_checkout.section.billing_address.form.address_label')}  />
                    <RHFTextField name="phoneNumber" label={translate('view.shop_checkout.section.billing_address.form.phone_number_label')}  />
                </Box>


                <Box
                sx={{
                    display: 'grid',
                    rowGap: 3,
                    columnGap: 2,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
                >
                    <RHFTextField name="city" label={translate('view.shop_checkout.section.billing_address.form.town_label')} />
                    <RHFTextField name="postalCode" label={translate('view.shop_checkout.section.billing_address.form.postal_code_label')}  />
                </Box>

                <RHFSelect name="country" label={translate('view.shop_checkout.section.billing_address.form.country_label')} >
                {countries.map((option) => (
                    <option key={option.code} value={option.label}>
                    {option.label}
                    </option>
                ))}
                </RHFSelect>
            </Stack>
            </DialogContent>

            <Divider />

            <DialogActions>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {translate('view.shop_checkout.section.billing_address.form.deliver_to_this_address')}
            </LoadingButton>
            <Button color="inherit" variant="outlined" onClick={onClose}>
                {translate('view.shop_checkout.section.billing_address.form.cancel_button_text')}
            </Button>
            </DialogActions>
        </FormProvider>
        </Dialog>
    );
}
