import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import studioSlice from './slices/studio/slice';
import { productSlice } from './slices/product/slice';
import { checkoutSlice } from './slices/checkout/slice';

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const checkoutPersistConfig = {
  key: 'checkout',
  storage,
  keyPrefix: 'redux-',
};

const rootReducer = combineReducers({
  // studio: persistReducer(studioPersisConfig, studioSlice.reducer),
  studio: studioSlice,
  checkout: persistReducer(checkoutPersistConfig, checkoutSlice.reducer),
  product: persistReducer(productPersistConfig, productSlice.reducer),
});

export { rootPersistConfig, rootReducer };
