import { ReactNode } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_DASHBOARD } from '../routes/paths';


type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [searchParams,] = useSearchParams('');
  
  if (isAuthenticated && user?.role === 'admin') {
    navigate(PATH_DASHBOARD.pampilles.list)
  }else if(isAuthenticated){
    if(searchParams.get('from')){
      navigate('/')
    }else{
      navigate(-1)
    }
  }
  return <>{children}</>;
}
