import { useState, useEffect, ReactNode } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { alpha, styled } from '@mui/material/styles';
import {
  List,
  Drawer,
  LinkProps,
  ListItemText,
  ListItemButton,
  ListItemButtonProps,
} from '@mui/material';
// config
import { NAVBAR } from 'src/config';
// components
import Logo from 'src/components/commons/Logo';
import Iconify from 'src/components/commons/Iconify';
import Scrollbar from 'src/components/commons/Scrollbar';
import { IconButtonAnimate } from 'src/components/animate';
//
import { MenuProps } from './type';
import menuConfig from 'src/layouts/main/header/MenuConfig';
import { PATH_AUTH } from 'src/routes/paths';
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

type StyleProps = LinkProps & ListItemButtonProps;

interface ListItemStyleProps extends StyleProps {
  component?: ReactNode;
  to?: string;
  end?: boolean;
}

const ListItemStyle = styled(ListItemButton)<ListItemStyleProps>(({ theme }) => ({
  ...theme.typography.body2,
  height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

export default function MenuMobile({ isOffset }: MenuProps) {
  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);
  const login_item = {
    'title': "login",
    'path': PATH_AUTH.login,
    'locale': 'layout.navigation.login_button',
    'icon': '',
  }
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (drawerOpen) {
      handleDrawerClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleDrawerOpen}
        sx={{
          ml: 1,
          ...(isOffset && { color: 'text.primary' }),
        }}
      >
        <Iconify icon={'eva:menu-2-fill'} />
      </IconButtonAnimate>

      <Drawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
        PaperProps={{ sx: { pb: 5, width: 260 } }}
      >
        <Scrollbar>
          <Logo sx={{ mx: 2.5, my: 3 }} />

          <List disablePadding>
            {menuConfig.map((link, index) => (
              <MenuMobileItem key={index} item={link} isOpen={open} onOpen={handleOpen} />
            ))}
          </List>
          <List disablePadding>
            <MenuMobileItem key={login_item.title} item={login_item} isOpen={open} onOpen={handleOpen} />
          </List>
        </Scrollbar>
      </Drawer>
    </>
  );
}

// ----------------------------------------------------------------------

type MenuMobileItemProps = {
  item: any;
  isOpen: boolean;
  onOpen: VoidFunction;
};

function MenuMobileItem({ item, isOpen, onOpen }: MenuMobileItemProps) {
  const { path, locale } = item;
  const {translate} = useLocales();
  
  return (
    <ListItemStyle
      to={path}
      component={RouterLink}
      end={path === '/'}
      sx={{
        '&.active': {
          color: 'primary.main',
          fontWeight: 'fontWeightMedium',
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        },
      }}
    >
      <ListItemText disableTypography primary={translate(locale)} />
    </ListItemStyle>
  );
}
