/**
 * 
 * @param base64Str 
 * @param MAX_WIDTH 
 * @param MAX_HEIGHT 
 * @returns 
 */
export async function reduce_image_file_size(base64Str:string, MAX_WIDTH = 450, MAX_HEIGHT = 450) {
  let resized_base64 = await new Promise((resolve) => {
      let img = new Image()
      img.src = base64Str
      img.onload = () => {
          let canvas = document.createElement('canvas')
          let width = img.width
          let height = img.height

          if (width > height) {
              if (width > MAX_WIDTH) {
                  height *= MAX_WIDTH / width
                  width = MAX_WIDTH
              }
          } else {
              if (height > MAX_HEIGHT) {
                  width *= MAX_HEIGHT / height
                  height = MAX_HEIGHT
              }
          }
          canvas.width = width
          canvas.height = height
          let ctx = canvas.getContext('2d')
          ctx!.drawImage(img, 0, 0, width, height)
          resolve(canvas.toDataURL())
      }
  });
  return resized_base64;
}

/**
 * 
 * @param dataUrl The image in base64 format
 * @param fileName Filename including file format
 * @returns File
 */
export async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName);
}