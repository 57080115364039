// form
import { useForm } from 'react-hook-form';

// @mui
import { Grid, Button, Box, Modal, Typography, LinearProgress, LinearProgressProps } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
import {
  // onGotoStep,
  onBackStep,
  resetCart,
  setOrderComplete,
} from 'src/redux/slices/checkout/actions';
// components
import Iconify from 'src/components/commons/Iconify';
import { FormProvider } from 'src/components/hook-form';
//
import CheckoutSummary from './CheckoutSummary';
import CheckoutBillingInfo from './CheckoutBillingInfo';
import useLocales from 'src/hooks/useLocales';

// service
import StripeService from 'src/services/stripeService';
import ModelService from 'src/services/modelService';
import { dataUrlToFile } from 'src/utils/image';
import useAuth from 'src/hooks/useAuth';
import { CreatingOrder } from 'src/@types/order';
import { useState } from 'react';

type FormValuesProps = {
  delivery: number;
};


function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function CheckoutPayment() {
  const dispatch = useDispatch();
  const {user} = useAuth();
  const { cart, total_ht, total_ttc, shipping, billing} = useSelector((state) => state.checkout);
  const { translate } = useLocales();
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleBackStep = () => {
    dispatch(onBackStep());
  };

  const defaultValues = {
    delivery: shipping,
  };

  const methods = useForm<FormValuesProps>({
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    setOpenOrderModal(true)
    try {
      const order:CreatingOrder = {
        products: [],
        deliveryAddressId: billing.id,
        billingAddressId: billing.id,
        price: parseFloat(total_ttc.toFixed(2))
      }

      for (const cartItem of cart){
        const model = {
          name: cartItem.model.pampille.name + '_' + cartItem.model.finition.name,
          pampilleId: cartItem.model.pampille.id,
          finitionId: cartItem.model.finition.id,
          price: parseFloat(cartItem.model.price),
          treatment: cartItem.model.treatment,
          marking: cartItem.model.marking,
          punchCreation: cartItem.model.punchCreation,
        }
        const {data:response} = await ModelService.post(model);
        const file = await dataUrlToFile(cartItem.model.cover, `model_preview_${response.id}_user_${user?.id}.png`)
        const fd = new FormData();
        fd.append('file', file)
        fd.append("modelId", response.id)
        await ModelService.upload(fd);
        const new_model = {modelId: response.id, quantity: cartItem.quantity}
        order.products.push(new_model)
        setProgress((prevProgress) => (prevProgress >= 100 ? 100/cart.length : prevProgress + 100/cart.length));
      }
      const {data:stripeResponse} = await StripeService.post(order);
      dispatch(resetCart());
      setOpenOrderModal(false);
      dispatch(setOrderComplete(true));
      window.location.href = stripeResponse.url;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Button
                size="small"
                color="inherit"
                onClick={handleBackStep}
                startIcon={<Iconify icon={'eva:arrow-ios-back-fill'} />}
              >
              {translate('view.shop_checkout.section.steps.back')}
            </Button>
          </Grid>
          <Grid item xs={12} md={8}>
            {/* <CheckoutDelivery
              onApplyShipping={handleApplyShipping}
              deliveryOptions={DELIVERY_OPTIONS}
            /> */}
            <CheckoutSummary
              cart={cart}
              total={total_ttc}
              subtotal={total_ht}
              shipping={shipping}
            />
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Procéder au paiement
            </LoadingButton>
          </Grid>

          <Grid item xs={12} md={4}>
            <CheckoutBillingInfo onBackStep={handleBackStep} />
          </Grid>
        </Grid>
      </FormProvider>

      <Modal
        open={openOrderModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <LinearProgressWithLabel value={progress} />
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {translate('view.shop_checkout.model_creation_loading')}
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
