import { createSlice } from '@reduxjs/toolkit';
import { StudioState } from '../../../@types/studio';
import _ from 'lodash';

const initialState: StudioState = {
    enableGrid: false,
    enableTextActionBar: false,
    enableImageActionBar: false,
    scale: 1,
    selectedItemIDs: [],
    selecto_targets: [],
    texts_front: [{
        'id': `canvas_text_item_0`,
        'content': '',
        'curve': null,
        'style': {
            'textAlign': 'center',
            'fontFamily': 'Public Sans',
            'textDecoration': 'none',
            'fontStyle': 'normal',
            'fontWeight': 'normal',
            'fontSize': '16px'
        },
    }],
    images_front: [],
};

const slice = createSlice({
    name: 'studio',
    initialState,
    reducers: {
        resetStudioStates(state) {
            state.images_front = []
            state.texts_front = []
            state.enableTextActionBar = false
            state.enableGrid = false
            state.enableImageActionBar = false
        },
        // STUDIO SETTING STATES
        enableGrid(state, action) {
            state.enableGrid = action.payload;
        },
        enableTextActionBar(state, action) {
            state.enableTextActionBar = action.payload;
        },
        enableImageActionBar(state, action) {
            state.enableImageActionBar = action.payload;
        },
        setScale(state, action){
            state.scale = action.payload
        },
        // STUDIO TEXTE STATES
        addText(state, action) {
            state.texts_front.push({
                'id': `canvas_text_item_${state.texts_front.length}`,
                'content': action.payload,
                'curve': null,
                'style': {
                    'textAlign': 'center',
                    'fontFamily': 'Public Sans',
                    'textDecoration': 'none',
                    'fontStyle': 'normal',
                    'fontWeight': 'normal',
                    'fontSize': '16px'
                },
            });
        },
        changeText(state, action){
            const index = state.texts_front.findIndex(x => x.id === action.payload.id)
            state.texts_front[index] = action.payload;
        },
        
        deleteText(state, action) {
            state.texts_front.splice(action.payload, 1);
            state.selecto_targets.splice(_.indexOf(state.selecto_targets, 
                _.find(state.selecto_targets, { id : `canvas_text_item_${action.payload}`})), 1);
        },

        // STUDIO IMAGE STATES
        addImage(state, action) {
            state.images_front.push({
                'id': `canvas_image_item_${state.images_front.length}`,
                'b64str': action.payload
            });
        },
        deleteImage(state, action) {
            const index = state.images_front.findIndex(x => x.id === action.payload.id)
            state.images_front.splice(index, 1);
            state.selecto_targets.splice(_.indexOf(state.selecto_targets, 
                _.find(state.selecto_targets, { id : action.payload.id})), 1);
        },

        // SELECTO & MOVEABLE
        setTargets(state, action) {
            state.selecto_targets = action.payload;
            state.selectedItemIDs = action.payload.map((item:any) => item.id)
        }
    }
});

export const studioSlice = slice;
export default slice.reducer;