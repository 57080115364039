import Router from './routes';
import ThemeProvider from './theme';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { ProgressBarStyle } from './components/commons/ProgressBar';
import ThemeLocalization from './components/settings/ThemeLocalization';

function App() {

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeLocalization>
          <ProgressBarStyle />
          <Router />
        </ThemeLocalization>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}

export default App;
