const fr = {
    // MESSAGES

    // COMMON VOCABULARY
    commons:{
        yes: 'Oui',
        no: 'Non',
        actions: {
            open: 'Ouvrir',
            close: 'Fermer',
            save: 'Enregistrer',
            create: 'Créer',
            edit: 'Modifier',
            delete: 'Supprimer',
        },

        orders:{
            my_orders: 'Mes commandes',
            no_orders: 'Pas de commandes enregistrées',
            status: {
                paid: 'Payée',
                created: 'Créée',
                passed: 'Passée'
            }
        },

        pampille:{
            finish: 'Finition',
            treatment: 'Traitement'
        }
    },

    // PAGES
    view: {
        // Home page
        home: {
            view_title: 'Accueil',
            section: {
                welcome: {
                    hi: 'Bonjour',
                    create_message: 'Créez votre pampille',
                    hero_message: 'Utilisez notre éditeur pour choisir un modèle et créer une pampille personnalisée.',
                    start_button: 'Commencer',
                    button_to_admin_text: 'Dashboard',
                    my_creations: 'Mes créations',
                },
                catalog: {
                    header_subtitle: 'Notre catalogue',
                    title: 'À chaque visuel, sa pampille',
                    description: 'Nous proposons un large choix de pampilles de tailles et de formes différentes afin de répondre à vos besoins les plus spécifiques et vous permettre de créer en toute liberté la pampille qui correspond à votre identité visuelle.',
                    toShopButton: 'Voir toutes les pampilles'
                },
                contact: {
                    title: 'Une question ? Une demande spécifique ?',
                    description: 'Adressez-nous votre projet de création de pampille.',
                    button_text: 'Contactez nous'
                }
            },
        },
        // Company info page
        company: {
            view_title: 'Notre savoir-faire',
            section: {
                info: {
                    header_subtitle: 'Qui sommes-nous',
                    title: 'Solutions industrielles en métallurgie depuis 1987',
                    description: 'ERODE crée et fabrique des accessoires métalliques ornementaux pour le mode, la décoration, les cosmétiques, l\'aéronautique et le bâtiment. Nos réalisations sont effectuées par découpe laser, emboutissage, matriçage de tous types de matériaux : fer blanc, laiton, acier, bois, plastique, papier, caoutchouc... ainsi que par traitement de surface (polissage et dépôt électrolytique.',
                    button: 'En savoir plus'
                },
                made_in_france: {
                    header_subtitle: 'Un savoir faire français',
                    title: 'Made in France',
                    description: 'De la conception de la pampille à sa personnalisation, toutes les étapes de fabrication sont assurées en France.'
                },
                shipping: {
                    title: 'Conception et envoi rapide',
                    description: 'Du passage de votre commande, à l\'envoi de votre commande en passant par la conception et la vérification de vos pampilles, Érode vous assure une livraison sous 2 semaines.'
                },
            }
        },
        // CGV
        cgv:{   
            view_title: 'Conditions générales de vente',
        },
        legal_notice:{
            view_title: 'Mentions Légales'
        },
        // Studio page
        studio: {
            view_title: 'Studio',
            section: {
                sidebar: {
                    text: {
                        title: 'Texte',
                        description: 'Modifiez votre texte ci-dessous ou modifiez le directement sur le studio.',
                        input_label: 'Texte à ajouter',
                        button_label: 'Nouvelle zone de texte',
                        empty_title: 'Vous n\'avez pas ajouter de texte au studio',
                    },
                    image: {
                        title: 'Image',
                        description: 'Importer une image ou un logo pour personnaliser votre pampille.',
                        format: 'Formats acceptés',
                        format_list: 'Seuls les formats suivants sont supportés : .jpg, .jpeg, .png, .gif.',
                        button_label: 'Importer',
                        empty_library_title: 'Vous n\'avez pas d\'images enregistrées',
                        empty_library_description: 'Vous pouvez en importer et les rendre accessibles à chaque connexion',
                        modal: {
                            add_button_text: 'Ajouter au studio'
                        }
                    },
                    settings: {
                        title: 'Paramètres',
                        description: 'Utilisez différents paramètres pour faciliter l\'alignement de vos design.',
                        dimensions_toggle: 'Règles et dimensions',
                        grid_toggle: 'Grille de positionnement'
                    },
                    summary: {
                        add_to_cart: 'Ajouter au panier',
                        success_message: 'Pampille ajoutée au panier',
                        success_model_save: 'Votre création à bien été enregistrée',
                        error_model_save: 'Vous devez être connecter pour enregistrer ce modèle.'
                    }
                },
                canvas:{
                    textActionBar: {
                        font: 'Typographie'
                    },
                    other_products: 'Voir d\'autres pampilles'
                },
                marking_modal:{
                    title: 'Choisissez votre type de gravure',
                    description: 'Vos créations peuvent être gravées par marquage laser ou frapper par poinçon. Le prix de la création du poinçon est de 150€ à la première commande.',
                    marking_type: 'Type de marquage',
                    graved: 'Gravure laser',
                    hit: 'Poinçon par frappe',
                    poincon: 'Avez-vous déjà commandé le poinçon associé à cette création ?',
                },
                model_modal:{
                    title: 'Enregistrer cette création',
                    description: 'Vous pourrez retrouver cette création sur votre compte pour la modifier plus tard.',
                    model_name: 'Nom de la création'
                }
            }
        },
        shop: {
            view_title: 'Nos pampilles',
            title: 'Catalogue des pampilles',
            subtitle: 'Nos pampilles',
            filter: {
                search_bar_label: 'Chercher un produit'
            },
            section: {
                card: {
                    on_quote: 'Sur devis'
                }
            }
        },
        shop_details: {
            view_title: 'Details produit',
            section: {
                breadcrumbs: {
                    title: 'Détails de la pampille',
                    subtitle: 'Nos pampilles'
                },
                search_bar: {
                    search: 'Rechercher une pampille',
                    not_found: 'Pampille inconnue',
                    please_enter_keyword: 'Veuillez saisir un mot-clé',
                    no_results: 'Aucun résultat n\'a été trouvé pour : ',
                    please_check: 'Veuillez saisir un autre mot ou vérifier son orthographe'
                },
                product_card: {
                    description: 'Description',
                    dimensions: 'Dimensions',
                    price: 'Prix',
                    finish: 'Finitions',
                    customize: 'Personnaliser'
                }
            }
        },
        shop_checkout: {
            view_title: 'Passer la commande',
            continue_shopping: 'Revenir au catalogue',
            model_creation_loading: 'Enregistrement de vos créations, vous allez être redirigé vers la page de paiement.',
            section: {
                breadcrumbs:{
                    title: 'Nos pampilles',
                    checkout: 'Panier'
                },
                billing_address: {
                    form: {
                        address_name_label: 'Nom de l\'adresse',
                        full_name_label: 'Prénom / Nom',
                        phone_number_label: 'Téléphone',
                        address_label: 'Adresse',
                        town_label: 'Ville',
                        postal_code_label: 'Code postal',
                        country_label: 'Pays',
                        add_button_text: 'Ajouter une nouvelle adresse',
                        deliver_to_this_address: 'Livrer à cette adresse',
                        add_address: 'Ajouter l\'adresse',
                        edit_address: 'Modifier l\'adresse',
                        cancel_button_text: 'Annuler',
                        use_as_default_address: 'Utiliser comme adresse par défaut' 
                    },
                    list: {
                        delete_button_text: 'Supprimer',
                        deliver_button_text: 'Livrer à cette adresse'
                    }
                },
                billing_address_card: {
                    title: 'Adresse de livraison',
                    edit_button_text: 'Modifier'
                },
                delivery_options_card:{
                    title: 'Option de livraison',
                    standard_delivery_title: 'Livraison Standard',
                    standard_delivery_description: 'Livré sous 15 jours ouvrés',
                    fast_delivery_title: 'Livraison Rapide',
                    fast_delivery_description: 'Livré sous 7 jours ouvrés'
                },
                payment_options_card:{
                    title: 'Options de paiement'
                },
                summary_card:{
                    title: 'Résumé de la commande',
                    subtotal: 'Sous-total',
                    shipping: 'Livraison',
                    total: 'Total',
                    total_ht: 'Total HT',
                    total_ttc: 'Total TTC',
                    vat_included: 'TVA incluse si applicable',
                    free: 'Gratuit'
                },
                steps: {
                    cart: 'Panier',
                    billing: 'Facturation et Livraison',
                    payment: 'Paiement',
                    back: 'Retour'
                },
                cart_list: {
                    title: 'Votre panier',
                    items: 'Produit(s)',
                    empty_title: 'Votre panier est vide',
                    empty_description: 'Vous n\'avez ajouter aucun élément à votre panier.',
                    table_head: {
                        product: 'Produit',
                        price: 'Prix unitaire',
                        quantity: 'Quantité',
                        total_price: 'Prix Total'
                    }
                },
                order_complete: {
                    title: 'Nous avons bien reçu votre commande',
                    thanks: 'Merci d\'avoir passer votre commande N° ',
                    notice: 'Nous vous enverrons une notification par e-mail lorsque votre commande sera expédiée.',
                    question: 'Si vous avez des questions ou requêtes concernant votre commande, n\'hésitez pas à nous contacter',
                    greetings: 'Bonne journée et à bientôt',
                    download_button_text: 'Télécharger mon récapitulatif',
                    back_to_shop: 'Retourner au catalogue',
                    back_to_orders: 'Voir mes commandes'
                }
            }
        },
        admin_dashboard: {
            view_title: 'Administration'
        },
        auth: {
            login: {
                view_title: 'Connexion',
                login: 'Se connecter',
                logout: 'Se déconnecter',
                details: 'Connectez-vous pour suivre vos commandes et enregistrer vos créations.',
                no_account: 'Vous n\'avez pas encore de compte ?',
                get_started: 'S\'enregistrer',
                form: {
                    title: 'Se connecter',
                    username_label: 'Nom d\'utilisateur',
                    email_label: 'Adresse mail',
                    password_label: 'Mot de passe',
                    button_text: 'Connexion',
                    error_text: 'Vos identifiants sont erronés',
                    validation_text: {
                        email: 'L\'adresse mail doit être valide',
                        password: 'Un mot de passe est obligatoire',
                    },
                    required_text: {
                        username: 'Un nom d\'utilisateur est requis',
                        email: 'Une adresse mail est requise',
                        password: 'Un mot de passe est requis',
                    },
                    register: 'S\'enregister',
                    account_exist: 'Vous avez déjà un compte ?',
                    remember_me: 'Se souvenir de moi',
                    forgotten_password: 'Mot de passe oublié ?',
                    error_message: {
                        'undefined': 'Une erreur s\'est produite, veuillez réessayer',
                        'Wrong password': 'Mot de passe incorrect',
                        "User doesn't exists": 'Cet utilisateur n\'existe pas',
                        'Request body validation failed': 'Une erreur s\'est produite. Veuillez essayer de nouveau.',
                        'Something went wrong': 'Une erreur s\'est produite, veuillez réessayer'
                    }
                }
            },
            register: {
                view_title: 'Créer un compte',
                create_account: 'S\'enregistrer',
                account_exist: 'Vous avez déjà un compte ?',
                register_button_label: 'S\'enregistrer',
                form: {
                    error_message: {
                        'Request body validation failed': 'Une erreur s\'est produite. Veuillez essayer de nouveau.',
                        'User already exists': 'Ce mail est déjà utilisé',
                        'Something went wrong': 'Une erreur s\'est produite, veuillez réessayer'
                    }
                }
            },
            forgotten_password: {
                view_title: 'Mot de passe oublié',
                form_button_text: 'Restaurer mon mot de passe',
                details: 'Renseignez l\'adresse mail de votre compte pour restaurer votre mot de passe.',
            },
            reset_password: {
                view_title: 'Restauration de mot de passe',
                form_button_text: 'Restaurer mon mot de passe',
                details: 'Veuillez renseigner un nouveau mot de passe pour vos futures connections.',
                success: 'Votre mot de passe a bien été modifié.'
            }
        },
        user_account: {
            view_title: 'Mon compte',
            orders: {
                view_title: 'Mes commandes',
                order_row:{
                    date: 'Commande du',
                    id: 'Identifiant',
                    total_price: 'Prix total',
                    status: 'Statut',
                    details: 'Détails'
                }
            },
            images: {
                view_title: 'Mes images',
                no_images: 'Vous n\'avez pas encore d\'images enregistrées',
                delete_success: 'Votre image à bien été supprimée.'
            },
            settings: {
                view_title: 'Mes paramètres',
                section: {
                    change_mail:{
                        title: 'Change d\'adresse mail',
                        description: ' Ici, vous pouvez modifier votre adresse mail pour vous connecter à votre compte.',
                        form: {
                            error: 'Nous ne pouvons modifier votre adresse mail',
                            success: 'Votre adresse mail à bien été modifier. Un mail vous a été envoyé pour la confirmer.',
                            labels:{
                                new_email: 'Nouvelle adresse mail',
                            },
                            validation_text:{
                                new_email: 'Doit être une adresse mail valide'
                            },
                            required_text:{
                                new_email: 'Nouvelle adresse mail requise'
                            }
                        }
                    },
                    change_password:{
                        title: 'Modification du mot de passe',
                        description: 'Vous pouvez modifier votre mot de passe à tout moment. Un mot de passe doit contenir au moins 6 caractères.',
                        form: {
                            error: 'Nous ne pouvons modifier votre mot de passe',
                            success: 'Votre mot de passe à bien été modifier',
                            labels:{
                                old_password: 'Ancien mot de passe',
                                new_password: 'Nouveau mot de passe'
                            },
                        }
                    },
                    delete_account: {
                        title: ' Suppression du compte',
                        description: 'Vous pouvez supprimer votre compte et toutes les données qui y sont associées.',
                        modal_title: 'Êtes-vous sûr(e) de vouloir supprimer votre compte ?',
                        modal_description: 'Aucunes données ne sera sauvegardées',
                    }
                }
            },
            models: {
                view_title: 'Mes créations',
                no_creations: 'Vous n\'avez pas encore de créations enregistrées.',
            },
            billing:{
                view_title: 'Moyens de paiements',
                no_addresses: 'Vous n\'avez pas encore d\'adresses enregistrées.'
            },
            shipping:{
                view_title: 'Mes adresses',
                delete_success: 'Adresse supprimée avec succès.',
            },
            model_card: {
                title: 'Créations',
                description: 'Retrouvez toutes vos créations enregistrées pour faciliter le passage de nouvelles commandes.'
            },
            order_card: {
                title: 'Commandes',
                description: 'Suivez l\'historique de vos commandes et re-commandez des articles plus rapidement.'
            },
            billing_card:{
                title: 'Moyens de paiement',
                description: 'Ajoutez, supprimez ou éditez vos moyens de paiement afin de faciliter le passage de vos commandes.'
            },
            shipping_card: {
                title: 'Adresses',
                description: 'Ajoutez, supprimez ou modifiez vos adresses de livraison et de facturation.'
            },
            image_card: {
                title: 'Mes images',
                description: 'Retrouvez les images que vous avez importé dans notre studio pour créer vos pampilles.'
            },
            setting_card: {
                title: 'Paramètres du compte',
                description: 'Modifiez les paramètres de sécurité de votre compte (Identifiant, mot de passe, mail ...).'
            },
            section: {
                breadcrumbs: {
                    my_account: 'Mon compte',
                    my_models: 'Mes créations',
                    my_images: 'Mes images',
                    my_billings: 'Paiements et adresses',
                    my_addresses: 'Mes adresses',
                    settings: 'Paramètres du compte'
                },
                user_card: {
                    client_number: 'Numéro client',
                    email: 'Adresse mail',
                },
                tabs: {
                    overview: 'Compte',
                    models: 'Créations',
                    orders: 'Commandes',
                    settings: 'Paramètres'
                },
                panels:{
                    overview: {
                        my_addresses: 'Mes adresses'
                    },
                    models: {
                        model_id: 'ID de la création',
                        product_name: 'Nom de la pampille',
                        product_id: 'N° d\'identification',
                        finish: 'Finition',
                        delete: 'Supprimer',
                        edit: 'Modifier',
                        add_cart: 'Ajouter au panier',
                        delete_success: 'Votre création à bien été supprimée',
                        delete_info: 'Votre création ne peut être supprimée car elle appartient à une commande en cours.'
                    }
                },
            }
        },
        contact: {
            view_title: 'Contact',
            section: {
                form: {
                    title: 'Besoin d\'informations ou d\'obtenir un devis ? Contactez-nous.',
                    name_label: 'Votre nom',
                    email_label: 'E-mail',
                    subject_label: 'Sujet de votre demande',
                    message_label: 'Votre message',
                    send_button_label: 'Envoyer',
                    required_text: {
                        name: 'Un nom est requis',
                        email: 'Une adresse mail est requise',
                        subject: 'Veuillez indiquer le sujet de votre demande',
                        message: 'Un message est requis'
                    },
                    validation_text: {
                        email: 'L\'adresse n\'est pas valide'
                    },
                    success_message: 'Nous vous remercions pour votre message. Nos équipes feront le maximum pour vous répondre dans les plus brefs délais.'
                }
            }
        },
        error: {
            not_found: {
                view_title: 'Erreur 404',
                title: 'Désolé, cette page n\'existe pas !',
                message: 'La ressource que vous cherchez n\'existe pas.',
                redirect_button_text: 'Revenir à l\'accueil'
            }
        },
        email_confirmation:{
            view_title: 'Confirmation email',
            true:{
                title: 'Félicitations, votre email à bien été confirmé',
                message: 'Vous pouvez maintenant enregistrer vos créations et personnaliser votre compte.',
            },
            false:{
                title: 'Impossible de confirmer votre adresse.',
                message: 'Veuillez vérifier que votre adresse mail est valide.',
            },
            redirect_button_text: 'Se connecter'
        }
    },

    // LAYOUT

    layout: {
        navigation: {
            home: 'Accueil',
            company: 'Notre savoir-faire',
            shop: 'Nos pampilles',
            admin: 'Panneau d\'administration',
            contact: 'Contact',
            my_account: 'Mon compte',
            login_button: 'Connexion'
        },
        footer: {
            copyright: 'Tous droits réservés',
            legal_notice: 'Mentions Légales',
            privacy_policy: 'Conditions générales de vente',
            contact_us: 'Nous contacter',
            our_products: 'Nos pampilles'
        },
        cart: {
            title: 'Votre panier',
            total: 'Total',
            quantity: 'Quantité',
            finish: 'Finition',
            unit_price: 'Prix unitaire',
            price: 'Prix',
            subtotal: 'Sous-total',
            total_ht: 'Total HT',
            total_ttc: 'Total TTC',
            empty: 'Panier vide',
            included_delivery_fee: 'Frais de livraison inclus',
            to_cart_button: 'Voir le panier',
            reset_cart_button: 'Supprimer le panier'
        }
    }
};

export default fr;
