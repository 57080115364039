import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { Box,IconButton, Divider, Typography, Stack, MenuItem } from '@mui/material';
import MenuPopover from '../../../components/commons/MenuPopover';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { PATH_AUTH, PATH_ACCOUNT, PATH_DASHBOARD} from 'src/routes/paths';
import useLocales from 'src/hooks/useLocales';
import MyAvatar from 'src/components/commons/MyAvatar';
import {capitalCase} from "change-case";
import { useDispatch } from 'src/redux/store';
import { resetCart } from 'src/redux/slices/checkout/actions';

const MENU_OPTIONS = [
  {
    label: 'layout.navigation.my_account',
    linkTo: PATH_ACCOUNT.root,
  },
];


export default function AccountPopover() {

  const { translate } = useLocales();
  const navigate = useNavigate();
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const isMountedRef = useIsMountedRef();
  const { user, logout } = useAuth();
  const dispatch = useDispatch();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      dispatch(resetCart());
      navigate(PATH_AUTH.login, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme:any) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user!.username}
          </Typography>
          <Typography variant="body2" noWrap>
            {capitalCase(user!.role)}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} 
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}>
              {translate(option.label)}
            </MenuItem>
          ))}
          {user?.role === 'admin' &&
            <MenuItem key={'admin'} 
              to={PATH_DASHBOARD.pampilles.list}
              component={RouterLink}
              onClick={handleClose}>
                {translate('layout.navigation.admin')}
            </MenuItem>
          }
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>{translate('view.auth.login.logout')}</MenuItem>
      </MenuPopover>
    </>
  );
}
