import axiosInstance from "src/utils/axios";
import HTTPService from "./httpService";

class ModelService extends HTTPService {
    upload(payload: any) {
        return axiosInstance.post(process.env.REACT_APP_HOST_API + this.end_point + '/upload', 
            payload, {headers: {'content-type': 'multipart/form-data'}
        });
    }

    getPrice(payload: any) {
        return axiosInstance.post(process.env.REACT_APP_HOST_API + this.end_point + '/price', payload);
    }
}
const service = new ModelService('models');


export default service;