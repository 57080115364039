import Iconify from '../../../components/commons/Iconify';

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Accueil',
    icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    path: '/',
    locale: 'layout.navigation.home'
  },
  {
    title: 'Notre savoir-faire',
    icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    path: '/our_company',
    locale: 'layout.navigation.company'
  },
  {
    title: 'Nos produits',
    icon: <Iconify icon={'eva:book-open-fill'} {...ICON_SIZE} />,
    path: '/shop',
    locale: 'layout.navigation.shop'
  },
  {
    title: 'Contact',
    icon: <Iconify icon={'eva:book-open-fill'} {...ICON_SIZE} />,
    path: '/contact',
    locale: 'layout.navigation.contact'
  },
];

export default menuConfig;
